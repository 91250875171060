import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { apiRoot } from '../config';
import { logout } from '../reducers/authReducer';

const BASE_URL = apiRoot;

const baseQuery = fetchBaseQuery({
  baseUrl: BASE_URL,
  prepareHeaders: (headers) => {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      headers.set('Authorization', accessToken);
    }
    return headers;
  },
});

const baseQueryWithRefresh = async (args, api, extraOptions) => {
  try {
    let result = await baseQuery(args, api, extraOptions);
    if (result?.error?.status === 403 && result?.error?.data?.message === 'Access token is invalid or expired') {
      const refreshToken = localStorage.getItem('refreshToken');
      if (refreshToken) {
        const refreshResult = await baseQuery({
          url: 'auth/refresh-token',
          method: 'POST',
          body: { refreshToken }
        }, { ...api, ...extraOptions });

        if (refreshResult?.data) {
          localStorage.setItem('accessToken', refreshResult.data.accessToken);
          localStorage.setItem('refreshToken', refreshResult.data.refreshToken);

          result = await baseQuery(args, api, extraOptions);
        } else {
          return api.dispatch(logout());
        }
      } else {
        return api.dispatch(logout());
      }
    }

    return result;
  } catch (err) {
    return { error: err, isError: true };
  }
};

export default baseQueryWithRefresh;
