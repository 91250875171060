import { createApi } from '@reduxjs/toolkit/query/react';
import baseQueryWithRefresh from './baseQueryWithRefresh';

export const companyApi = createApi({
  reducerPath: 'companyApi',
  baseQuery: baseQueryWithRefresh,
  endpoints: (builder) => ({
    createCompany: builder.mutation({
      query: (data) => ({
        url: `companies`,
        method: 'POST',
        body: data
      }),
    }),
    checkTwilio: builder.mutation({
      query: (data) => ({
        url: `companies/check-twilio`,
        method: 'POST',
        body: data
      }),
    }),
    getCompanies: builder.query({
      query: () => ({
        url: 'companies'
      }),
    }),
    getCompanyById: builder.query({
      query: (id) => ({
        url: `companies/${id}`
      }),
    }),
    updateCompany: builder.mutation({
      query: ({ id, body }) => ({
        url: `companies/${id}`,
        method: 'PUT',
        body,
      }),
    }),
    archiveCompany: builder.mutation({
      query: (id) => ({
        url: `companies/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useCreateCompanyMutation,
  useCheckTwilioMutation,
  useGetCompaniesQuery,
  useGetCompanyByIdQuery,
  useUpdateCompanyMutation,
  useArchiveCompanyMutation,
} = companyApi;
