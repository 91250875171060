import React from 'react';
import currentUser from '../../HOCs/currentUser.hoc';
import CallLocalComponent from './callLocal.component';

const CallLocalPage = () => {
  return (
    <div className="main-content mt-5">
      <div className="row text-muted"><h3>Call to the bot (Local)</h3></div>
      <div className="row bg-white p-2 h90p">
        <CallLocalComponent />
      </div>
      <div className="empty-bottom-block" />
    </div>
  );
};

export default currentUser(CallLocalPage);
