import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';
import { CallIcon, InfoIcon, UploadIcon, DownloadIcon } from '../../common/icons';
import languages from './languages.json'
import { apiRoot } from '../../config';
import { useBatchWSCallMutation } from '../../services/callApi';
import { useGetTemplatesListQuery } from '../../services/templateApi';
import { getAccessToken } from '../../reducers/authReducer';

const BatchCallComponent = () => {
  const accessToken = useSelector(getAccessToken);

  const [csvFile, setCsvFile] = useState(null);
  const [fileName, setFileName] = useState("");

  const [supportedLanguages] = useState(languages);
  const [defaultLanguage, setDefaultLanguage] = useState(JSON?.parse(localStorage.getItem('call-language')) || languages[0]);
  const [template, setTemplate] = useState({});
  const [callData, setCallData] = useState(false);
  const [callError, setCallError] = useState(false);

  const [initBatchCall, { isLoading, isError }] = useBatchWSCallMutation();
  const { data: templates } = useGetTemplatesListQuery(null, { refetchOnMountOrArgChange: true });

  useEffect(() => {
    if (templates && Object.keys(template).length === 0) {
      setTemplate(templates[0]);
    }
  }, [templates, template]);

  const onChangeLanguage = (e) => {
    const { value } = e.target;
    const { id } = e.target.selectedOptions[0];
    setCallData(false);
    setDefaultLanguage({ name: id, lang: value });
    localStorage.setItem('call-language', JSON.stringify({ name: id, lang: value }));
  }

  const onChangeTemplate = (e) => {
    setCallData(false);
    console.log('e.target.selectedOptions --->', e.target.selectedOptions)
    const { id } = e.target.selectedOptions[0];
    console.log('id --->', id, templates)
    const newTemplate = templates.find(t => t?.id === Number(id));
    console.log('newTemplate --->', newTemplate)
    setTemplate(newTemplate);
  }

  const handleFileChange = async (event) => {
    if (!event?.target?.files) {
      console.error('No file selected');
      return;
    }
    setCallData(false);
    setCsvFile(event.target?.files[0]);
    setFileName(event.target?.files[0]?.name || '');
  };

  const getFileName = (filePath) => {
    const parts = filePath?.split(/[/\\]/);
    return parts[parts?.length - 1];
  }

  const handleDownload = async () => {
    console.log('template --->', template)
    if (!template?.id) { return }

    try {
      const configFileName = getFileName(template?.config_file);
      const response = await fetch(`${apiRoot}templates/download-config/${template.id}`, {
        headers: {
          Authorization: accessToken
        }
      });
      if (response.status === 200) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', configFileName);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      }
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  };

  const handleBatchCall = async () => {
    if (!csvFile || !template || !defaultLanguage) {
      return;
    }
    setCallData(false);
    try {
      const formData = new FormData();
      formData.append('file', csvFile);
      formData.append('template_id', template.id);
      formData.append('language', defaultLanguage.lang);

      const response = await initBatchCall(formData);
      if (!response.error) {
        setCallError(false);
        setCallData(response?.data?.message);
      } else {
        if (response?.error?.data?.message?.includes('This template is not suitable')) {
          setCallError(response?.error?.data?.message);
        } else {
          setCallError('Something went wrong... Please try again later');
        }
      }
    } catch (error) {
      console.error('Error sending phone to API:', error);
    }
  }


  return (
    <div className="speech-container">
      <div className="bot-select mt-3 mb-3">
        <span>Template:</span>
        <select className="form-select w243 bot-select ms-2" onChange={onChangeTemplate}>
          {templates?.map(temp => (
            <option key={temp.id} id={temp.id} value={temp.title}>
              {temp.title}
            </option>
          ))}
        </select>
      </div>
      <div className="bot-select ">
        <label htmlFor="fileInput" className="btn btn-success w200 ms-2 mt-2 mb-1">
          <><UploadIcon /> Upload CSV</>
          <input
            id="fileInput"
            type="file"
            accept=".csv"
            style={{ display: 'none' }}
            onChange={handleFileChange}
          />
        </label>
        <button
          className="btn btn-primary w200 ms-2 mt-2 mb-1"
          onClick={handleDownload}
          disabled={Object.keys(template).length === 0}
        >
          <DownloadIcon /> Download CSV
        </button>
      </div>
      <div className="csv-file-label">{fileName ? <>File: {fileName}</> : ''}</div>
      <div className="bot-select mb-3 mt-3"><span>Language:</span>
        <select className="form-select w240 bot-select ms-2" onChange={onChangeLanguage} value={defaultLanguage?.lang}>
          {supportedLanguages?.map(lang => (
            <option key={lang.lang} id={lang.name} value={lang.lang}>
              {lang.name}
            </option>
          ))}
        </select>
      </div>
      <div className=" mt-3">
        <Button
          variant="primary"
          className="call-button"
          onClick={handleBatchCall}
          disabled={!csvFile}
        >
          <CallIcon className="mb-1 call-button-icon" /> {isLoading ? <div className="d-inline-flex">Requested <div class="call-dot-container">
            <div className="call-dot"></div>
            <div className="call-dot"></div>
            <div className="call-dot"></div>
          </div></div> : ' Request a calls'}
        </Button>
      </div>
      <br />
      {callData ? <><br /><p>{callData}</p></> : null}
      {isError ? <><br /><p className="alert-text"><span>Error: </span>{callError}</p></> : null}
      <br />
      <div className="call-info-block p-0 mb-2">
        <p><InfoIcon /> Select a template. Each template contains its own configuration CSV file.</p>
        <p><InfoIcon /> Download a CSV file. The first row should be the headers of the table. Do not change the headers. The column containing phone numbers must have a header labeled as "phone".</p>
        <p><InfoIcon /> Enter data in each row for each header. Phone should contains the country code and number.</p>
        <p><InfoIcon /> Upload the file with data, select other settings and click the "Request a calls" button.</p>
      </div>
    </div>
  );
};

export default BatchCallComponent;
