import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import currentUser from '../../HOCs/currentUser.hoc';
import CompanyTable from './companiesTable.component';
import { useGetCompaniesQuery, useUpdateCompanyMutation, useArchiveCompanyMutation } from '../../services/companyApi';
import ConfirmationModal from '../../common/modals/confirmation.modal';
import { AddCompanyIcon, PlusBoardIcon } from '../../common/icons';

import './companies.css';

const CompaniesAdminPage = () => {
  const { data: companies, isLoading, refetch } = useGetCompaniesQuery(undefined, { refetchOnMountOrArgChange: true });
  const [archiveCompany] = useArchiveCompanyMutation();
  const [updateCompany] = useUpdateCompanyMutation();

  const history = useHistory();

  const [showArchiveModal, setShowArchiveModal] = useState(false);
  const [companyArchivingID, setCompanyArchivingID] = useState(false);
  const [companyArchivingError, setCompanyArchivingError] = useState(false);

  const [showUnarchiveModal, setShowUnarchiveModal] = useState(false);
  const [companyUnarchiveID, setCompanyUnarchiveID] = useState(false);
  const [companyUnarchiveError, setCompanyUnarchiveError] = useState(false);


  const onEdit = (companyID) => {
    history.push(`/companies/edit/${companyID}`);
  }

  const onArchive = (id) => {
    setCompanyArchivingID(id);
    setShowArchiveModal(true);
    setCompanyArchivingError(false);
  }

  const onHideArchive = () => {
    setCompanyArchivingID(false);
    setShowArchiveModal(false);
    setCompanyArchivingError(false);
  }

  const onConfirmArchive = async () => {
    if (companyArchivingID) {
      const result = await archiveCompany(companyArchivingID);
      if (!result.error) {
        onHideArchive();
        await refetch();
      } else {
        setCompanyArchivingError(result?.error?.data?.message);
      }
    } else {
      onHideArchive();
    }
  }

  const onUnarchive = async (id) => {
    setCompanyUnarchiveID(id);
    setShowUnarchiveModal(true);
    setCompanyUnarchiveError(false);
  }

  const onHideUrchive = () => {
    setCompanyUnarchiveID(false);
    setShowUnarchiveModal(false);
    setCompanyUnarchiveError(false);
  }

  const onConfirmUnarchive = async () => {
    if (companyUnarchiveID) {
      const result = await updateCompany({ id: companyUnarchiveID, body: { "is_deleted": false }});
      if (!result.error) {
        onHideUrchive();
        await refetch();
      } else {
        setCompanyUnarchiveError(result?.error?.data?.message);
      }
    } else {
      onHideUrchive();
    }
  }

  return (
    <div className="main-content mt-5">
      <div className="row text-muted "><h3>Companies <span className="fs-6">List</span></h3></div>
      <div className="row bg-white">
        <button className="btn btn-success w200 ms-2 mt-2 mb-1" onClick={() => history.push('/companies/add')}>
          <PlusBoardIcon /> Add company
        </button>
      </div>
      <CompanyTable companies={companies} isLoading={isLoading} onEdit={onEdit} onArchive={onArchive} onUnarchive={onUnarchive} />
      <ConfirmationModal
        show={showArchiveModal}
        onHide={onHideArchive}
        onConfirm={onConfirmArchive}
        title="Archive Company?"
        description={`Are you sure you want to archive Company (ID: ${companyArchivingID}) ? Users of this company will not be able to log in after this.`}
        error={companyArchivingError}
      />
      <ConfirmationModal
        show={showUnarchiveModal}
        onHide={onHideUrchive}
        onConfirm={onConfirmUnarchive}
        title="Unarchive Company?"
        description={`Are you sure you want to Unarchive Company (ID: ${companyUnarchiveID}) ?`}
        error={companyUnarchiveError}
      />
    </div>
  )
}

export default currentUser(CompaniesAdminPage);