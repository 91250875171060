import React from 'react';
import { Table } from 'react-bootstrap';

function TableWrapper({ children, className='' }) {
  return (
    <div className={`row pb-2 ${className}`}>
      <Table>
        {children}
      </Table>
    </div>
  );
}

export default TableWrapper;