import { createApi } from '@reduxjs/toolkit/query/react';
import baseQueryWithRefresh from './baseQueryWithRefresh';

export const smsApi = createApi({
  reducerPath: 'smsApi',
  baseQuery: baseQueryWithRefresh,
  endpoints: (builder) => ({
    batchSMS: builder.mutation({
      query: (formData) => ({
        url: `sms/init-batch-sms`,
        method: 'POST',
        body: formData
      }),
    }),
    getSMSList: builder.query({
      query: () => ({
        url: 'sms/group-list'
      }),
    }),
    getSmsConv: builder.query({
      query: (convID) => ({
        url: `sms/conversation/${convID}`
      }),
    }),
    deleteSMSConv: builder.mutation({
      query: (convID) => ({
        url: `sms/conversation/${convID}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useBatchSMSMutation,
  useGetSMSListQuery,
  useGetSmsConvQuery,
  useDeleteSMSConvMutation,
} = smsApi;
