import React from 'react';
import TableWrapper from '../../common/table/tableWrapper.common';
import TableHeader from '../../common/table/tableHeader.common';
import { ShowIcon, DeleteIcon, DownloadFileIcon } from '../../common/icons';
import formatDateTime from '../../utils/formatDateTime';

function CampaignTable({ campaigns = [], isLoading, onView, onDelete, onDownload, currentUser }) {
  const currentUserRole = currentUser?.role?.name;
  const isAdmin = currentUserRole && (currentUserRole.includes('admin') || currentUserRole.includes('superadmin'));
  
  const headers = ['ID', 'Name', 'User', 'Total Count', 'Creation date', 'Actions'];

  const tableBody = () => (
    <tbody>
      {campaigns?.map(campaign => (
        <tr key={campaign.id} style={{ verticalAlign: 'middle' }}>
          <td>{campaign.id}</td>
          <td>{campaign.name}</td>
          <td>{campaign.user_name}</td>
          <td>{campaign.total_count}</td>
          <td>{formatDateTime(campaign.created_date)}</td>
          <td>
            <div className="f-t-actions mw-135">
              <div className="cursor-p" onClick={() => onView(campaign.id)} title="View">
                <ShowIcon />
              </div>
              <div className="cursor-p" onClick={() => onDownload(campaign)} title="Download original file">
                <DownloadFileIcon />
              </div>
              {isAdmin && (
                <div className="cursor-p" onClick={() => onDelete(campaign.id)} title="Delete">
                  <DeleteIcon />
                </div>
              )}
            </div>
          </td>
        </tr>
      ))}
    </tbody>
  );

  const tableLoader = () => (
    <tbody>
      <tr>
        <td colSpan={headers.length} className="text-center">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </td>
      </tr>
    </tbody>
  );

  return (
    <TableWrapper>
      <TableHeader headers={headers} />
      {isLoading ? tableLoader() : tableBody()}
    </TableWrapper>
  );
}

export default CampaignTable;
