import React, { useEffect, useRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import currentUser from '../../HOCs/currentUser.hoc';
import DetailsWrapper from '../../common/wrappers/detailsWrapper.component';
import { Button } from 'react-bootstrap';
import { useGetSmsConvQuery } from '../../services/smsApi';
import formatDateTime from '../../utils/formatDateTime';
import { RefreshIcon } from '../../common/icons';

import './conversation.css';

const ConversationSMS = () => {
  const history = useHistory()
  const { convID } = useParams();
  const { data: conversation, isLoading, isError: isConvError, refetch } = useGetSmsConvQuery(convID, { refetchOnMountOrArgChange: true });

  const convDotRef = useRef(null);

  useEffect(() => {
    if (convDotRef.current) {
      convDotRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  const refreshList = async () => {
    await refetch();
  }

  const BackButton = () => (
    <div className="conv-back-button-container p-0 mb-2">
      <Button variant="secondary ms-3 w100" onClick={() => history.push('/sms')}>
        Back
      </Button>
    </div>
  )

  if (isLoading) {
    return (
      <DetailsWrapper title="Calls" label="Show" blockTitle="Details">
        <div>Loading...</div>;
        <BackButton />
      </DetailsWrapper>
    )
  }

  if (isConvError) {
    return (
      <DetailsWrapper title="Calls" label="Show" blockTitle="Details">
        <div>Error fetching sms data</div>
        <BackButton />
      </DetailsWrapper>
    )
  }

  return (
    <DetailsWrapper
      title="SMS"
      label="Show"
      blockTitle={<>
        Details <div className='refresh-sms-block'><button className="btn btn-success w200 ms-2 mt-2 mb-1 " onClick={refreshList}>
          <RefreshIcon /> {isLoading ? 'Loading...' : 'Refresh conv'}
        </button></div>
      </>}
    >
      <div>
        <div className="conv-messages">
          {conversation?.map(conv => (<div key={conv?.id} className={`message ${conv?.role === 'bot' ? 'bot-message' : 'user-message'}`}>
              <div className="chat-messages-role">{conv.role}{conv?.role === 'user' && conv?.userPhone && ` (${conv?.userPhone || ''})`}</div>
              <div className="text">{conv.text}</div>
              <div className="chat-messages-time">{formatDateTime(conv.time)}</div>
            </div>
          ))}
        </div>
      </div>
      <BackButton />
    </DetailsWrapper>
  );
};

export default currentUser(ConversationSMS);
