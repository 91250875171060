import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';

const statusesList = [
  { id: null, title: 'not selected' },
  { id: 'busy', title: 'busy' },
  { id: 'completed', title: 'completed' },
  { id: 'failed', title: 'failed' },
  { id: 'in-progress', title: 'in progress' },
  { id: 'no answer', title: 'no answer' },
  { id: 'requested', title: 'requested' },
];

const dispositionsList = [
  { id: null, title: 'not selected' },
  { id: 'CALL_BACK_SCHEDULED', title: 'CALL_BACK_SCHEDULED' },
  { id: 'COMPLETED_ACTION', title: 'COMPLETED_ACTION' },
  { id: 'FOLLOW_UP_REQUIRED', title: 'FOLLOW_UP_REQUIRED' },
  { id: 'INTERESTED', title: 'INTERESTED' },
  { id: 'NEEDS_MORE_INFO', title: 'NEEDS_MORE_INFO' },
  { id: 'NO_CONTACT_MADE', title: 'NO_CONTACT_MADE' },
  { id: 'NOT_INTERESTED', title: 'NOT_INTERESTED' },
  { id: 'NOT_QUALIFIED', title: 'NOT_QUALIFIED' },
  { id: 'OBJECTION_RAISED', title: 'OBJECTION_RAISED' },
  { id: 'TRANSFERRED', title: 'TRANSFERRED' },
  { id: '', title: 'Empty value' },
];

const ExportCallsModal = ({ show, onHide, onConfirm, error }) => {
  const [count, setCount] = useState(null);
  const [status, setStatus] = useState({ id: null, title: 'not selected' });
  const [disposition, setDisposition] = useState({ id: null, title: 'not selected' });

  const onChangeCount = (e) => {
    if (e.target.value === '') setCount(null);
    setCount(e.target.value);
  }

  const onChangeStatus = (e) => {
    const { value } = e.target;
    const { id } = e.target.selectedOptions[0];

    setStatus({ id: id, title: value });
  }

  const onChangeDispositionsList = (e) => {
    const { value } = e.target;
    const { id } = e.target.selectedOptions[0];

    setDisposition({ id: id, title: value });
  }

  const onClose = () => {
    setCount(null);
    setStatus({ id: null, title: 'not selected' });
    setDisposition({ id: null, title: 'not selected' });
    onHide();
  }

  const onExport = async () => {
    const body = {};
    if (count) body.count = count?.trim();
    if (status?.id !== null) body.status = status.id;
    if (disposition?.id !== null) body.disposition = disposition.id;

    const result = await onConfirm(body);

    if (result) {
      setCount(null);
      setStatus({ id: null, title: 'not selected' });
      setDisposition({ id: null, title: 'not selected' });
    }
  }

  return (
    <Modal show={show} onHide={onClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Export calls</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="bot-select mb-3">
          <span>Status:</span>
          <select className="form-select w280 bot-select ms-2" onChange={onChangeStatus}>
            {statusesList?.map(temp => (
              <option key={temp.id} id={temp.id} value={temp.title}>
                {temp.title}
              </option>
            ))}
          </select>
        </div>
        <div className="bot-select mb-3">
          <span>Disposition:</span>
          <select className="form-select w243 bot-select ms-2" onChange={onChangeDispositionsList}>
            {dispositionsList?.map(temp => (
              <option key={temp.id} id={temp.id} value={temp.title}>
                {temp.title}
              </option>
            ))}
          </select>
        </div>
        <div className="bot-select mb-3"><span>Count:</span>
          <input
            type="count"
            className={`form-control ms-2 mt-1 w280`}
            placeholder="Calls count (if needed)"
            value={count}
            onChange={onChangeCount}
            autoFocus
          />
        </div>
        <div className="empty-block alert-text">{error || null}</div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose} autoFocus>
          Cancel
        </Button>
        <Button variant="primary" onClick={onExport}>
          Export
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ExportCallsModal;
