import { configureStore } from '@reduxjs/toolkit';
import rootReducer from '../reducers';
import { authApi } from '../services/authApi';
import { fileApi } from '../services/fileApi';
import { userApi } from '../services/userApi';
import { roleApi } from '../services/roleApi';
import { templateApi } from '../services/templateApi';
import { vocalApi } from '../services/vocalApi';
import { callApi } from '../services/callApi';
import { smsApi } from '../services/smsApi';


export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(authApi.middleware)
      .concat(fileApi.middleware)
      .concat(userApi.middleware)
      .concat(roleApi.middleware)
      .concat(templateApi.middleware)
      .concat(vocalApi.middleware)
      .concat(callApi.middleware)
      .concat(smsApi.middleware)
});
