import { createApi } from '@reduxjs/toolkit/query/react';
import baseQueryWithRefresh from './baseQueryWithRefresh';

export const callApi = createApi({
  reducerPath: 'callApi',
  baseQuery: baseQueryWithRefresh,
  endpoints: (builder) => ({
    initCall: builder.mutation({
      query: (body) => ({
        url: `calls/init`,
        method: 'POST',
        body,
      }),
    }),
    getCallsList: builder.query({
      query: ({ page = 1, limit = 50, phone, status, start_time, compaignID }) => ({
        url: 'calls',
        params: { page, limit, phone, status, start_time, campaign: compaignID },
      }),
    }),
    getCallByID: builder.query({
      query: (callID) => ({
        url: `calls/${callID}`
      }),
    }),
    getConversationData: builder.query({
      query: (callID) => ({
        url: `conversation?call_id=${callID}`
      }),
    }),
    updateCall: builder.mutation({
      query: ({callID, body}) => ({
        url: `calls/${callID}`,
        method: 'PUT',
        body,
      }),
    }),
    deleteCall: builder.mutation({
      query: (callID) => ({
        url: `calls/${callID}`,
        method: 'DELETE',
      }),
    }),
    getCallStatusByID: builder.mutation({
      query: (callID) => ({
        url: `calls/${callID}`
      }),
    }),
    initBlandCall: builder.mutation({
      query: (body) => ({
        url: `bland/call`,
        method: 'POST',
        body,
      }),
    }),
    initBlandBatchCall: builder.mutation({
      query: (body) => ({
        url: `bland/init-batch-call`,
        method: 'POST',
        body,
      }),
    }),
    getBlandCallStatusByID: builder.mutation({
      query: (body) => ({
        url: `bland/check-status`,
        method: 'POST',
        body,
      }),
    }),
    initSipCall: builder.mutation({
      query: (body) => ({
        url: `dialer/init-twilio-call`,
        method: 'POST',
        body,
      }),
    }),
    initNoSipCall: builder.mutation({
      query: (body) => ({
        url: `dialer/init-twilio-no-sip-call`,
        method: 'POST',
        body,
      }),
    }),
    batchCall: builder.mutation({
      query: (formData) => ({
        url: `dialer/init-batch-call`,
        method: 'POST',
        body: formData
      }),
    }),
    getConvPathway: builder.query({
      query: () => ({
        url: 'bland/get-conv-pathways'
      }),
    }),
    getBlandNumberData: builder.query({
      query: () => ({
        url: 'bland/bland-number'
      }),
    }),
    updateIncomingData: builder.mutation({
      query: (formData) => ({
        url: `bland/update-incoming-call`,
        method: 'POST',
        body: formData
      }),
    }),
    batchWSCall: builder.mutation({
      query: (formData) => ({
        url: `ws/init-batch-call`,
        method: 'POST',
        body: formData
      }),
    }),
    initLocalCall: builder.mutation({
      query: (body) => ({
        url: `calls-local/init-local`,
        method: 'POST',
        body,
      }),
    }),
    exportCalls: builder.mutation({
      query: (body) => ({
        url: `calls/export`,
        method: 'POST',
        body,
        responseHandler: (response) => response.blob(),
      }),
    }),
  }),
});

export const {
  useInitCallMutation,
  useGetCallsListQuery,
  useGetConversationDataQuery,
  useDeleteCallMutation,
  useUpdateCallMutation,
  useGetCallByIDQuery,
  useGetCallStatusByIDMutation,
  useInitBlandCallMutation,
  useGetBlandCallStatusByIDMutation,
  useInitSipCallMutation,
  useInitNoSipCallMutation,
  useGetConvPathwayQuery,
  useGetBlandNumberDataQuery,
  useUpdateIncomingDataMutation,
  useBatchCallMutation,
  useBatchWSCallMutation,
  useInitLocalCallMutation,
  useInitBlandBatchCallMutation,
  useExportCallsMutation,
} = callApi;
