import { createApi } from '@reduxjs/toolkit/query/react';
import baseQueryWithRefresh from './baseQueryWithRefresh';

export const campaingApi = createApi({
  reducerPath: 'campaingApi',
  baseQuery: baseQueryWithRefresh,
  endpoints: (builder) => ({
    // ======= Campaign =======
    createCampaign: builder.mutation({
      query: (body) => ({
        url: 'campaing',
        method: 'POST',
        body,
      }),
    }),
    getCampaigns: builder.query({
      query: () => ({
        url: 'campaing',
      }),
    }),
    getCampaignByID: builder.query({
      query: (id) => `campaing/${id}`,
    }),
    updateCampaign: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `campaing/${id}`,
        method: 'PUT',
        body,
      }),
    }),
    deleteCampaign: builder.mutation({
      query: (id) => ({
        url: `campaing/${id}`,
        method: 'DELETE',
      }),
    }),
    downloadCampaignFile: builder.mutation({
      queryFn: async (id, _queryApi, _extraOptions, fetchWithBQ) => {
        // Выполняем запрос через fetchWithBQ
        const result = await fetchWithBQ({
          url: `campaing/download/${id}`,
          method: 'GET',
        });
        if (result.error) return { error: result.error };
        try {
          // Извлекаем Blob из оригинального ответа
          const blob = await result.meta.response.blob();
          return { data: blob };
        } catch (error) {
          return { error: { status: result.meta.response.status, error: error.toString() } };
        }
      },
    }),
    updateTotalCount: builder.mutation({
      query: ({ id, delta }) => ({
        url: `campaing/totalCount/${id}`,
        method: 'PUT',
        body: { delta },
      }),
    }),

    // ======= Campaign Item =======
    CreateCampaignItem: builder.mutation({
      query: (body) => ({
        url: 'campaing-item',
        method: 'POST',
        body,
      }),
    }),
    getCampaignItems: builder.query({
      query: ({ campaign_id, status }) => {
        const params = new URLSearchParams();
        params.append('campaign_id', campaign_id);
        if (status) params.append('status', status);
        return `campaing-item?${params.toString()}`;
      },
    }),
    getCampaignItemByID: builder.query({
      query: (id) => `campaing-item/${id}`,
    }),
    updateCampaignItem: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `campaing-item/${id}`,
        method: 'PUT',
        body,
      }),
    }),
    deleteCampaignItem: builder.mutation({
      query: (id) => ({
        url: `campaing-item/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useCreateCampaignMutation,
  useGetCampaignsQuery,
  useGetCampaignByIDQuery,
  useUpdateCampaignMutation,
  useDeleteCampaignMutation,
  useDownloadCampaignFileMutation,
  useUpdateTotalCountMutation,
  useCreateCampaignItemMutation,
  useGetCampaignItemsQuery,
  useGetCampaignItemByIDQuery,
  useUpdateCampaignItemMutation,
  useDeleteCampaignItemMutation,
} = campaingApi;
