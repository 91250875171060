import React from 'react';
import currentUser from '../../HOCs/currentUser.hoc';
import SpeechToText from './botEgged.component';

import './vocal.css';

const VocalEggedPage = () => {
  return (
    <div className="main-content mt-5">
      <div className="row text-muted"><h3>Egged buses info</h3></div>
      <div className="row bg-white p-2 h90p">
        <SpeechToText />
      </div>
      <div className="empty-bottom-block" />
    </div>
  );
};

export default currentUser(VocalEggedPage);
