import { createApi } from '@reduxjs/toolkit/query/react';
import baseQueryWithRefresh from './baseQueryWithRefresh';

export const callApi = createApi({
  reducerPath: 'callApi',
  baseQuery: baseQueryWithRefresh,
  endpoints: (builder) => ({
    initCall: builder.mutation({
      query: (body) => ({
        url: `calls/init`,
        method: 'POST',
        body,
      }),
    }),
    getCallsList: builder.query({
      query: ({ page = 1, limit = 50, phone, status, start_time }) => ({
        url: 'calls',
        params: { page, limit, phone, status, start_time },
      }),
    }),
    getCallByID: builder.query({
      query: (callID) => ({
        url: `calls/${callID}`
      }),
    }),
    getConversationData: builder.query({
      query: (callID) => ({
        url: `conversation?call_id=${callID}`
      }),
    }),
    updateCall: builder.mutation({
      query: ({callID, body}) => ({
        url: `calls/${callID}`,
        method: 'PUT',
        body,
      }),
    }),
    deleteCall: builder.mutation({
      query: (callID) => ({
        url: `calls/${callID}`,
        method: 'DELETE',
      }),
    }),
    getCallStatusByID: builder.mutation({
      query: (callID) => ({
        url: `calls/${callID}`
      }),
    }),
    initBlandCall: builder.mutation({
      query: (body) => ({
        url: `bland/call`,
        method: 'POST',
        body,
      }),
    }),
    getBlandCallStatusByID: builder.mutation({
      query: (body) => ({
        url: `bland/check-status`,
        method: 'POST',
        body,
      }),
    }),
    initSipCall: builder.mutation({
      query: (body) => ({
        url: `dialer/init-twilio-call`,
        method: 'POST',
        body,
      }),
    }),
    initNoSipCall: builder.mutation({
      query: (body) => ({
        url: `dialer/init-twilio-no-sip-call`,
        method: 'POST',
        body,
      }),
    }),
    batchCall: builder.mutation({
      query: (formData) => ({
        url: `dialer/init-batch-call`,
        method: 'POST',
        body: formData
      }),
    }),
    batchWSCall: builder.mutation({
      query: (formData) => ({
        url: `ws/init-batch-call`,
        method: 'POST',
        body: formData
      }),
    }),
  }),
});

export const {
  useInitCallMutation,
  useGetCallsListQuery,
  useGetConversationDataQuery,
  useDeleteCallMutation,
  useUpdateCallMutation,
  useGetCallByIDQuery,
  useGetCallStatusByIDMutation,
  useInitBlandCallMutation,
  useGetBlandCallStatusByIDMutation,
  useInitSipCallMutation,
  useInitNoSipCallMutation,
  useBatchCallMutation,
  useBatchWSCallMutation,
} = callApi;
