import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Nav } from 'react-bootstrap';
import {
  FilesIcon, TemplateIcon, UsersIcon, VocalIcon, CallIcon, CallsIcon, SMSIcon, SMSListIcon,
  LocalCallIcon, TTSIcon, TabTextIcon, HeadsetIcon, CompanySettingsIcon
} from '../icons';
import './menu.css';

const SideMenu = () => {
  const history = useHistory();
  const location = useLocation();
  const user = useSelector((state) => state?.me);

  return (
    <div className="sidebar">
      <Nav className="flex-column">
        <div className="header"><p>Dial Bot</p></div>
        <Nav.Item>
          <Nav.Link
            className={location.pathname.includes('/files') ? 'active' : ''}
            onClick={() => history.push('/files')}
          >
            <FilesIcon /> Files
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link
            className={location.pathname.includes('/vocal') ? 'active' : ''}
            onClick={() => history.push('/vocal')}
          >
            <VocalIcon /> Talk to the bot
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link
            className={location.pathname.endsWith('/call') ? 'active' : ''}
            onClick={() => history.push('/call')}
          >
            <CallIcon /> Call to the bot
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link
            className={location.pathname.endsWith('/call_v2') ? 'active' : ''}
            onClick={() => history.push('/call_v2')}
          >
            <CallIcon /> Call to the bot v2.0
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link
            className={location.pathname.endsWith('/call_v3') ? 'active' : ''}
            onClick={() => history.push('/call_v3')}
          >
            <CallIcon /> Call to the bot v3.0
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link
            className={location.pathname.endsWith('/call_v3.1') ? 'active' : ''}
            onClick={() => history.push('/call_v3.1')}
          >
            <CallIcon /> Call to the bot v3.1
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link
            className={location.pathname.endsWith('/batch-call') ? 'active' : ''}
            onClick={() => history.push('/batch-call')}
          >
            <CallIcon /> Batch of Calls
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link
            className={location.pathname.endsWith('/batch-call_v4') ? 'active' : ''}
            onClick={() => history.push('/batch-call_v4')}
          >
            <CallIcon /> Batch of Calls v4
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link
            className={location.pathname.endsWith('/batch-sms') ? 'active' : ''}
            onClick={() => history.push('/batch-sms')}
          >
            <SMSIcon /> Send SMS
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link
            className={location.pathname.includes('/templates') ? 'active' : ''}
            onClick={() => history.push('/templates')}
          >
            <TemplateIcon /> Templates
          </Nav.Link>
        </Nav.Item>
        {user?.role?.name?.includes('admin') || user?.role?.name?.includes('superadmin')
          ? <Nav.Item>
            <Nav.Link
              className={location.pathname.includes('/users') ? 'active' : ''}
              onClick={() => history.push('/users')}
            >
              <UsersIcon /> Users list
            </Nav.Link>
          </Nav.Item>
          : null
        }
        {user?.role?.name?.includes('admin') || user?.role?.name?.includes('superadmin')
          ? <Nav.Item>
            <Nav.Link
              className={location.pathname.includes('/calls') ? 'active' : ''}
              onClick={() => history.push('/calls')}
            >
              <CallsIcon /> Calls list
            </Nav.Link>
          </Nav.Item>
          : null
        }
        {user?.role?.name?.includes('admin') || user?.role?.name?.includes('superadmin')
          ? <Nav.Item>
            <Nav.Link
              className={location.pathname.includes('/sms') ? 'active' : ''}
              onClick={() => history.push('/sms')}
            >
              <SMSListIcon /> SMS list
            </Nav.Link>
          </Nav.Item>
          : null
        }
        {user?.role?.name?.includes('companyadmin')
          ? <Nav.Item>
            <Nav.Link
              className={location.pathname.includes('/companies') ? 'active' : ''}
              onClick={() => history.push('/companies')}
            >
              <CompanySettingsIcon /> Companies list
            </Nav.Link>
          </Nav.Item>
          : null
        }
        {user?.email === 'itay.user@test.com' ? "__________________________________" : null}
        {user?.email === 'itay.user@test.com' ? (
          <Nav.Item>
            <Nav.Link
              className={location.pathname.endsWith('/local-call-demo') ? 'active' : ''}
              onClick={() => history.push('/local-call-demo')}
            >
              <LocalCallIcon /> Local Call Demo
            </Nav.Link>
          </Nav.Item>
        ) : null}
        {user?.email === 'itay.user@test.com' ? (
          <Nav.Item>
            <Nav.Link
              className={location.pathname.endsWith('/local-stt-training') ? 'active' : ''}
              onClick={() => history.push('/local-stt-training')}
            >
              <TTSIcon /> Local STT training
            </Nav.Link>
          </Nav.Item>
        ) : null}
        {user?.email === 'itay.user@test.com' ? (
          <Nav.Item>
            <Nav.Link
              className={location.pathname.endsWith('/local-llama-training') ? 'active' : ''}
              onClick={() => history.push('/local-llama-training')}
            >
              <TabTextIcon /> Local Llama testing
            </Nav.Link>
          </Nav.Item>
        ) : null}
        {user?.email === 'itay.user@test.com' ? (
          <Nav.Item>
            <Nav.Link
              className={location.pathname.endsWith('/local-tts-training') ? 'active' : ''}
              onClick={() => history.push('/local-tts-training')}
            >
              <HeadsetIcon /> Local TTS Sentence training
            </Nav.Link>
          </Nav.Item>
        ) : null}
        {user?.email === 'itay.user@test.com' ? (
          <Nav.Item>
            <Nav.Link
              className={location.pathname.endsWith('/local-tts-training-files') ? 'active' : ''}
              onClick={() => history.push('/local-tts-training-files')}
            >
              <HeadsetIcon /> Local TTS Names training
            </Nav.Link>
          </Nav.Item>
        ) : null}
      </Nav>
    </div>
  );
};

export default SideMenu;
