import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { VocalIcon, InfoIcon } from '../../common/icons';
import { useAskQuestionMutation, useGetAudioMutation } from '../../services/vocalApi';
import { useGetTemplatesListQuery } from '../../services/templateApi';
import { useUpdateCallMutation } from '../../services/callApi';

const SpeechToText = () => {
  const [transcript, setTranscript] = useState('');
  const [recognition, setRecognition] = useState(null);
  const [recording, setRecording] = useState(false);
  const [waitingResponse, setWaitingResponse] = useState(false);
  const [answer, setAnswer] = useState(false);
  const [lastTranscriptChange, setLastTranscriptChange] = useState(null);
  const [template, setTemplate] = useState({});
  const [history, addToHistory] = useState([]);
  const [call, setCall] = useState(false);

  const [getAnswer, { isError: isAnswerError }] = useAskQuestionMutation();
  const [getAudio] = useGetAudioMutation();
  const [updateCall] = useUpdateCallMutation();

  const [supportedLanguages, setSupportedLanguages] = useState([]);
  const [defaultLanguage, setDefaultLanguage] = useState(JSON?.parse(localStorage.getItem('language')) || null);
  const { data: templates } = useGetTemplatesListQuery(null, { refetchOnMountOrArgChange: true });

  useEffect(() => {
    if (templates?.length && Object.keys(template)?.length === 0) {
      setTemplate(templates[0]);
    }
  }, [templates, template]);

  useEffect(() => {
    const currentCall = call;

    const cleanup = () => {
      completeCall(currentCall);
    };

    return cleanup;
  }, [call]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      completeCall(call);
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [call]);

  useEffect(() => {
    const handleVoicesChanged = () => {
      const voices = window.speechSynthesis.getVoices();

      const uniqueLanguagesSet = new Set();
      voices.forEach(voice => {
        const langCode = voice.lang.split('-')[0];
        uniqueLanguagesSet.add(langCode);
      });

      const languages = [...uniqueLanguagesSet].map(lang => {
        const voice = voices.find(v => v.lang.startsWith(lang));
        return {
          name: voice.name.replace(/.*- (\w+)(?:\s*\(.*\))?/, '$1').trim(),
          lang: voice.lang
        };
      });

      const defalLanfFromStore = JSON?.parse(localStorage.getItem('language'));

      if (!defalLanfFromStore) {
        const defaultLang = languages.find(lang => lang.lang === 'en-US');
        const defaultLanguage = defaultLang ? defaultLang : languages[0];
        setDefaultLanguage(defaultLanguage);
      }

      setSupportedLanguages(languages);

    };

    if (window.speechSynthesis.getVoices().length > 0) {
      handleVoicesChanged();
    }

    window.speechSynthesis.onvoiceschanged = handleVoicesChanged;

    return () => {
      window.speechSynthesis.onvoiceschanged = null;
    };
  }, []);

  useEffect(() => {
    let timer;

    const sendTextAfterDelay = () => {
      clearTimeout(timer);
      if (Date.now() - lastTranscriptChange > 1500) {
        sendTextToAPI();
      } else {
        timer = setTimeout(sendTextAfterDelay, 1500 - (Date.now() - lastTranscriptChange));
      }
    };

    if (recording && transcript.trim() !== '') {
      sendTextAfterDelay();
    }

    return () => clearTimeout(timer);
  }, [transcript, recording, lastTranscriptChange]);

  const handleTranscriptChange = () => {
    setLastTranscriptChange(Date.now());
  };

  const startSpeechRecognition = () => {
    const recognition = new window.webkitSpeechRecognition();
    recognition.continuous = true;
    recognition.interimResults = true;
    recognition.lang = defaultLanguage?.lang || 'en-US';

    recognition.onstart = () => {
      console.log('Speech recognition started');
      setRecording(true);
    };

    recognition.onresult = event => {
      const interimTranscript = Array.from(event.results)
        .map(result => result[0].transcript)
        .join('');
      setTranscript(interimTranscript);
      handleTranscriptChange();
    };

    recognition.onerror = event => {
      if (event.error === 'no-speech') {
        startSpeechRecognition();
      }
    };

    recognition.onend = () => {
      console.log('Speech recognition ended');
      recognition.stop();
    };

    recognition.start();
    setRecognition(recognition);
  };

  const stopSpeechRecognition = () => {
    if (recognition) {
      recognition.stop();
      setRecording(false);
    }
  };

  const handleClick = async () => {
    if (recording) {
      addToHistory([]);
      stopSpeechRecognition();
      setCall(false);
    } else {
      startSpeechRecognition();
    }
  };

  const chunkArray = (arr, size) => {
    const chunkedArr = [];
    for (let i = 0; i < arr.length; i += size) {
      chunkedArr.push(arr.slice(i, i + size));
    }
    return chunkedArr;
  };

  const promiseChunk = async (chunkResponses) => {
    for await (const additionalResponse of chunkResponses) {
      if (!additionalResponse?.error && additionalResponse?.data) {
        await playAudio(additionalResponse.data.audio, additionalResponse.data.text, true);
      }
    }
  }

  const sendTextToAPI = async () => {
    if (!transcript.trim()) return;

    setAnswer(false);
    stopSpeechRecognition();
    setWaitingResponse(true);

    const historyArray = [...history];

    try {
      const getAnswerReqData = {
        history: [...historyArray, { role: 'user', content: transcript }],
        template_id: template?.id,
        language: defaultLanguage?.lang
      };
      if (!!call) getAnswerReqData.callID = call;
      const response = await getAnswer(getAnswerReqData);

      historyArray.push({ role: 'user', content: transcript });

      if (!response.error) {
        const { text, audio, otherSentences, callID } = response.data;

        setCall(callID);
        const otherSentencesText = otherSentences?.length ? otherSentences.map(sentence => Object.values(sentence)[0])?.join(' ') : '';
        historyArray.push({ role: 'assistant', content: `${text} ${otherSentencesText}` });

        let additionalResponses = [];
        let promiseAudio = playAudio(audio, text);
        if (!!otherSentences && Array.isArray(otherSentences)) {
          if (otherSentences.length > 5) {
            const chunkedOtherSentences = chunkArray(otherSentences, 5);
            let chankCount = 0;
            for (const chunk of chunkedOtherSentences) {
              chankCount += 1;
              const parallelRequests = chunk.map(sentence => {
                const value = Object.keys(sentence)[0];
                return getAudio({ message: sentence[value] });
              });

              const chunkResponses = await Promise.all([promiseAudio, ...parallelRequests]);
              if (chankCount !== chunkedOtherSentences?.length) {
                promiseAudio = promiseChunk(chunkResponses);
              } else {
                additionalResponses = chunkResponses;
              }
            }
          } else {
            const parallelRequests = otherSentences.map(sentence => {
              const value = Object.keys(sentence)[0];
              return getAudio({ message: sentence[value] });
            });

            additionalResponses = await Promise.all([promiseAudio, ...parallelRequests]);
          }
        } else {
          await promiseAudio;
        }

        if (additionalResponses?.length) {
          for await (const additionalResponse of additionalResponses) {
            if (!additionalResponse?.error && additionalResponse?.data) {
              await playAudio(additionalResponse.data.audio, additionalResponse.data.text, true);
            }
          }
        }
      }
    } catch (error) {
      console.error('Error sending text to API:', error);
    } finally {
      setTranscript('');
      startSpeechRecognition();
      if (historyArray?.length > 9) {
        addToHistory(historyArray.slice(-9));
      } else {
        addToHistory(historyArray);
      }
      setWaitingResponse(false);
    }
  };

  const playAudio = async (audioData, text, isAddText = false) => {
    try {
      const audioBlob = new Blob([Uint8Array.from(atob(audioData), c => c.charCodeAt(0))], { type: 'audio/mpeg' });
      const audioUrl = URL.createObjectURL(audioBlob);

      const audioElement = new Audio(audioUrl);

      audioElement.addEventListener('loadedmetadata', () => { });

      await new Promise((resolve, reject) => {
        audioElement.addEventListener('ended', resolve);
        audioElement.addEventListener('error', reject);
        audioElement.addEventListener('canplay', () => {
          setTimeout(() => {
            if (isAddText) {
              setAnswer(prevAnswer => prevAnswer + '\n' + text);
            } else {
              setAnswer(text);
            }
            audioElement.play()
              .catch(error => {
                console.error('Error starting audio playback:', error);
              });
          }, !isAddText ? 500 : 0);
        });
      });

    } catch (error) {
      console.error('Error starting audio playback:', error);
    }
  };

  const onChangeLanguage = async (e) => {
    const { value } = e.target;
    const { id } = e.target.selectedOptions[0];
    if (recording) {
      addToHistory([]);
      stopSpeechRecognition();
      setCall(false);
    }
    setAnswer(false);
    setDefaultLanguage({ name: id, lang: value });
    localStorage.setItem('language', JSON.stringify({ name: id, lang: value }));
  }

  const onChangeTemplate = (e) => {
    const { value } = e.target;
    const { id } = e.target.selectedOptions[0];

    if (recording) {
      addToHistory([]);
      stopSpeechRecognition();
      setCall(false);
    }
    setAnswer(false);

    setTemplate({ id: id, title: value });
  }

  const completeCall = async (currentCall) => {
    if (currentCall) {
      await updateCall({
        callID: call, body: {
          status: 'completed',
          end_time: new Date().toISOString().slice(0, 19).replace('T', ' ')
        }
      });
    }
  }

  return (
    <div className="speech-container">
      {defaultLanguage?.lang && (
        <div className="bot-select mt-1 mb-1"><span>Language:</span>
          <select className="form-select w243 bot-select" onChange={onChangeLanguage} value={defaultLanguage?.lang} disabled={waitingResponse}>
            {supportedLanguages?.map(lang => (
              <option key={lang.lang} id={lang.name} value={lang.lang}>
                {lang.name}
              </option>
            ))}
          </select>
        </div>)}
      <div className="bot-select mt-2">
        <span>Template:</span>
        <select className="form-select w243 bot-select ms-1" onChange={onChangeTemplate} disabled={waitingResponse}>
          {templates?.map(temp => (
            <option key={temp.id} id={temp.id} value={temp.title}>
              {temp.title}
            </option>
          ))}
        </select>
      </div>
      <div className="speech-block mt-4">
        <Button
          variant={recording ? "danger" : "primary"}
          className={`rounded-circle microphone-button ${recording ? 'recording' : ''}`}
          onClick={handleClick}
          disabled={waitingResponse}
        >
          <VocalIcon width="25px" height="25px" className="" />

        </Button>
        {waitingResponse && <div className="wave-container">
          <div className="wave"></div>
          <div className="wave-bottom"></div>
        </div>}
      </div>
      <br />
      <div>
        <p><span>Question: </span>{transcript}</p>
        <p className="d-flex"><span>Answer: </span><span className='answ-block'>{answer ? answer : ''}</span></p>
        {isAnswerError ? <p className="alert-text"><span>Error: </span>Something went wrong... Please repeat your question</p> : null}
        <br />
      </div>
      <div className="speech-info-block p-0 mb-2">
        <p><InfoIcon /> To get started, click on the blue microphone button and ask a question.</p>
        <p><InfoIcon /> To finish, click on the red microphone button.</p>
      </div>
    </div>
  );
};

export default SpeechToText;
