import React from 'react';
import TableWrapper from '../../common/table/tableWrapper.common';
import TableHeader from '../../common/table/tableHeader.common';
import { EditIcon, NoEditIcon, CheckIcon, UnchekIcon, LockIcon, UnlockIcon } from '../../common/icons';

const CompanyTable = ({ companies, isLoading, onEdit, onArchive, onUnarchive }) => {
  const headers = ['ID', 'Name', 'Users Count', 'Twilio Connect', 'Sip Connect', 'SMS Connect', 'Status', 'Actions'];

  const getTitle = (title) => {
    if (title) {
      if (title?.length > 20) {
        return title.substring(0, 25) + '...'
      } else {
        return title;
      }
    }
    return '';
  }

  const companyTableBody = () => (
    <tbody>
      {companies?.map(company => (
        <tr key={company?.id} style={{ verticalAlign: 'middle' }}>
          <td>{company?.id || ''}</td>
          <td style={{ width: '220px' }} title={company?.name}>{getTitle(company?.name)}</td>
          <td>{company?.usersCount || 0}</td>
          <td>{company?.twilioConnect ? <CheckIcon /> : <UnchekIcon />}</td>
          <td>{company?.sipConnect ? <CheckIcon /> : <UnchekIcon />}</td>
          <td>{company?.smsConnect ? <CheckIcon /> : <UnchekIcon />}</td>
          <td style={{ width: '110px' }}>{company?.is_deleted ? 'Archived' : 'Active'}</td>
          <td>
            <div className="f-t-actions mw-50">
              <div
                className="cursor-p"
                onClick={() => !company?.is_deleted && onEdit(company?.id)}
                title={company?.is_deleted ? 'You cannot edit an Archived company.' : 'Edit'} >
                {company?.is_deleted ? <NoEditIcon /> : <EditIcon />}
              </div>
              {
                (company?.is_deleted)
                  ? <div className="cursor-p" onClick={() => onUnarchive(company.id)} title="Unarchive"><UnlockIcon /></div>
                  : <div className="cursor-p" onClick={() => onArchive(company.id)} title="Archive"><LockIcon /></div>
              }
            </div>
          </td>
        </tr>
      ))}
    </tbody>
  );

  const companyTableLoader = () => (
    <tbody>
      <tr>
        <td colSpan="8" className="text-center">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </td>
      </tr>
    </tbody>
  );

  return (
    <TableWrapper>
      <TableHeader headers={headers} />
      {isLoading ? companyTableLoader() : companyTableBody()}
    </TableWrapper>
  );
}

export default CompanyTable;