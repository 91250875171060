// src/components/campaign/campaignItemsPage.component.jsx

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import currentUser from '../../HOCs/currentUser.hoc';
import DetailsWrapper from '../../common/wrappers/detailsWrapper.component';
import TableWrapper from '../../common/table/tableWrapper.common';
import TableHeader from '../../common/table/tableHeader.common';
import formatDateTime from '../../utils/formatDateTime';

import {
  useGetCampaignItemsQuery,
  useDeleteCampaignItemMutation,
  useUpdateCampaignItemMutation,
  useCreateCampaignItemMutation,
} from '../../services/campaingApi';

import {
  AddFileIcon,
  DeleteIcon,
  EditIcon,
  CheckIcon,
  CancelIcon,
} from '../../common/icons';

import ConfirmationModal from '../../common/modals/confirmation.modal';

const CampaignItemsPage = () => {
  const { campaignID } = useParams();

  // ====== RTK Query hooks ======
  const { data: itemsData, isLoading, refetch } = useGetCampaignItemsQuery({ campaign_id: campaignID });
  const [deleteItem] = useDeleteCampaignItemMutation();
  const [updateItem] = useUpdateCampaignItemMutation();
  const [createCampaignItem] = useCreateCampaignItemMutation();

  // ====== Состояния для редактирования существующих элементов ======
  const [editingRowId, setEditingRowId] = useState(null);
  const [editValues, setEditValues] = useState({ phone: '', extra_fields: {} });

  // Хендлер изменения phone (при редактировании существующего элемента)
  const onChangePhone = (e) => {
    setEditValues((prev) => ({ ...prev, phone: e.target.value }));
  };

  // Хендлер изменения любого поля extra_fields (при редактировании существующего элемента)
  const onChangeExtraField = (key, value) => {
    setEditValues((prev) => ({
      ...prev,
      extra_fields: {
        ...prev.extra_fields,
        [key]: value,
      },
    }));
  };

  // ====== Состояния для добавления нового элемента ======
  const [addingNewItem, setAddingNewItem] = useState(false);
  const [newItemValues, setNewItemValues] = useState({ phone: '', extra_fields: {} });

  // Хендлеры для нового элемента
  const onChangeNewItemPhone = (e) => {
    setNewItemValues((prev) => ({ ...prev, phone: e.target.value }));
  };
  const onChangeNewItemExtraField = (key, value) => {
    setNewItemValues((prev) => ({
      ...prev,
      extra_fields: {
        ...prev.extra_fields,
        [key]: value,
      },
    }));
  };

  // ====== Состояния для удаления через ConfirmationModal ======
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [itemDeletingID, setItemDeletingID] = useState(null);
  const [itemDeletingError, setItemDeletingError] = useState(null);

  // ====== Логика для динамических ключей (extra_fields) ======
  const [dynamicKeys, setDynamicKeys] = useState([]);
  useEffect(() => {
    if (itemsData && Array.isArray(itemsData)) {
      const allKeys = new Set();
      itemsData.forEach((item) => {
        if (item.extra_fields) {
          Object.keys(item.extra_fields).forEach((k) => allKeys.add(k));
        }
      });
      setDynamicKeys(Array.from(allKeys));
    }
  }, [itemsData]);

  // Заголовки таблицы
  const allHeaders = [
    'ID',
    'Phone',
    ...dynamicKeys, // вставляем динамические заголовки
    'Status',
    'Disposition',
    'Number of attempts',
    'Edit User',
    'Create Date',
    'Edit Date',
    'Actions',
  ];

  // ====== Редактирование существующих элементов ======
  const onEdit = (item) => {
    setEditingRowId(item.id);
    setEditValues({
      phone: item.phone,
      extra_fields: { ...item.extra_fields },
    });
  };
  const onCancelEdit = () => {
    setEditingRowId(null);
    setEditValues({ phone: '', extra_fields: {} });
  };
  const onSaveEdit = async (item) => {
    try {
      const updated = {
        id: item.id,
        phone: editValues.phone,
        extra_fields: editValues.extra_fields,
      };
      await updateItem(updated).unwrap();
      setEditingRowId(null);
      await refetch();
    } catch (err) {
      console.error('Error updating item:', err);
    }
  };

  // ====== Удаление через ConfirmationModal ======
  const onDeleteClick = (itemId) => {
    setItemDeletingID(itemId);
    setShowDeleteModal(true);
    setItemDeletingError(null);
  };
  const onHideDelete = () => {
    setItemDeletingID(null);
    setShowDeleteModal(false);
    setItemDeletingError(null);
  };
  const onConfirmDelete = async () => {
    if (!itemDeletingID) return onHideDelete();
    try {
      const result = await deleteItem(itemDeletingID).unwrap();
      if (result) {
        await refetch();
        onHideDelete();
      }
    } catch (err) {
      console.error('Error deleting item:', err);
      setItemDeletingError(err?.data?.message || 'Error deleting item');
    }
  };

  // ====== Добавление нового элемента ======
  const onAddNewItemClick = () => {
    setAddingNewItem(true);
    setNewItemValues({ phone: '', extra_fields: {} });
  };
  const onCancelNewItem = () => {
    setAddingNewItem(false);
    setNewItemValues({ phone: '', extra_fields: {} });
  };
  const onSaveNewItem = async () => {
    const body = {
      campaign_id: Number(campaignID),
      phone: newItemValues.phone,
      extra_fields: newItemValues.extra_fields,
    };
    try {
      await createCampaignItem(body).unwrap();
      setAddingNewItem(false);
      setNewItemValues({ phone: '', extra_fields: {} });
      await refetch();
    } catch (err) {
      console.error('Error adding new item:', err);
      // Можно обработать ошибку, если нужно
    }
  };

  // Генерация <tbody>
  const tableBody = () => {
    if (!itemsData || !Array.isArray(itemsData)) {
      return (
        <tbody>
          <tr>
            <td colSpan={allHeaders.length}>No items found</td>
          </tr>
        </tbody>
      );
    }

    return (
      <tbody>
        {/* Строка для добавления нового item */}
        {addingNewItem && (
          <tr>
            <td className="table-item">New</td>
            {/* Phone */}
            <td className="table-item table-item-width">
              <input
                type="text"
                className="form-control form-control-tbl-padding"
                value={newItemValues.phone}
                onChange={onChangeNewItemPhone}
              />
            </td>
            {/* Динамические поля */}
            {dynamicKeys.map((key) => {
              const val = newItemValues.extra_fields[key] || '';
              return (
                <td className="table-item table-item-width" key={key}>
                  <input
                    type="text"
                    className="form-control form-control-tbl-padding"
                    value={val}
                    onChange={(e) => onChangeNewItemExtraField(key, e.target.value)}
                  />
                </td>
              );
            })}
            {/* Остальные поля (Status, Disposition, etc.) оставим пустыми */}
            <td className="table-item" />
            <td className="table-item" />
            <td className="table-item" />
            <td className="table-item" />
            <td className="table-item" />
            <td className="table-item" />

            {/* Actions */}
            <td className="table-item table-sticky-col">
              <div
                style={{ display: 'inline-block', cursor: 'pointer', marginRight: '10px' }}
                onClick={onSaveNewItem}
                title="Save new item"
              >
                <CheckIcon />
              </div>
              <div
                style={{ display: 'inline-block', cursor: 'pointer' }}
                onClick={onCancelNewItem}
                title="Cancel new item"
              >
                <CancelIcon />
              </div>
            </td>
          </tr>
        )}

        {/* Существующие items */}
        {itemsData.map((item) => {
          const isEditing = editingRowId === item.id;

          return (
            <tr key={item.id}>
              {/* ID */}
              <td className="table-item table-item-id">{item.id}</td>

              {/* Phone */}
              <td className="table-item table-item-width">
                {isEditing ? (
                  <input
                    type="text"
                    className="form-control form-control-tbl-padding"
                    value={editValues.phone}
                    onChange={onChangePhone}
                  />
                ) : (
                  <span>{item.phone}</span>
                )}
              </td>

              {/* Динамические поля */}
              {dynamicKeys.map((key) => {
                const val = item.extra_fields ? item.extra_fields[key] : '';
                return (
                  <td className="table-item table-item-width" key={key}>
                    {isEditing ? (
                      <input
                        type="text"
                        className="form-control form-control-tbl-padding"
                        value={editValues.extra_fields[key] || ''}
                        onChange={(e) => onChangeExtraField(key, e.target.value)}
                      />
                    ) : (
                      <span>{val}</span>
                    )}
                  </td>
                );
              })}

              {/* Status */}
              <td className="table-item">{item.status}</td>

              {/* Disposition */}
              <td className="table-item">{item.disposition}</td>

              {/* Count */}
              <td className="table-item table-item-width">{item.count}</td>

              {/* Edit User */}
              <td className="table-item table-item-width">{item.user_name || ''}</td>

              {/* Create Date */}
              <td className="table-item">{formatDateTime(item.created_date)}</td>

              {/* Edit Date */}
              <td className="table-item">{formatDateTime(item.updated_date)}</td>

              {/* Actions */}
              <td className="table-item table-sticky-col">
                {isEditing ? (
                  <>
                    <div
                      style={{ display: 'inline-block', cursor: 'pointer', marginRight: '10px' }}
                      onClick={() => onSaveEdit(item)}
                      title="Save"
                    >
                      <CheckIcon />
                    </div>
                    <div
                      style={{ display: 'inline-block', cursor: 'pointer' }}
                      onClick={onCancelEdit}
                      title="Cancel"
                    >
                      <CancelIcon />
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      style={{ display: 'inline-block', cursor: 'pointer', marginRight: '10px' }}
                      onClick={() => onEdit(item)}
                      title="Edit"
                    >
                      <EditIcon />
                    </div>
                    <div
                      style={{ display: 'inline-block', cursor: 'pointer' }}
                      onClick={() => onDeleteClick(item.id)}
                      title="Delete"
                    >
                      <DeleteIcon />
                    </div>
                  </>
                )}
              </td>
            </tr>
          );
        })}
      </tbody>
    );
  };

  // Состояние «Loading...»
  const tableLoader = () => (
    <tbody>
      <tr style={{ height: '55px' }}>
        <td colSpan={allHeaders.length} className="text-center">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </td>
      </tr>
    </tbody>
  );

  // Кнопка "Add new item"
  const renderAddButton = () => (
    <button
      className="btn btn-success w200 ms-2 mt--8"
      onClick={onAddNewItemClick}
    >
      <AddFileIcon /> Add new item
    </button>
  );

  return (
    <DetailsWrapper title="Campaign Items" label="List" blockButton={renderAddButton()} className="main-campaing-wrapper">
      <TableWrapper className="teble-campaint-items-wrapper table-wrapper">
        <TableHeader headers={allHeaders} isActiontSticky={true} />
        {isLoading ? tableLoader() : tableBody()}
      </TableWrapper>

      {/* ConfirmationModal для удаления */}
      <ConfirmationModal
        show={showDeleteModal}
        onHide={onHideDelete}
        onConfirm={onConfirmDelete}
        title="Delete item?"
        description={`Are you sure you want to delete item (ID=${itemDeletingID})?`}
        error={itemDeletingError}
      />
    </DetailsWrapper>
  );
};

export default currentUser(CampaignItemsPage);
