import { createApi } from '@reduxjs/toolkit/query/react';
import baseQueryWithRefresh from './baseQueryWithRefresh';

export const vocalApi = createApi({
  reducerPath: 'vocalApi',
  baseQuery: baseQueryWithRefresh,
  endpoints: (builder) => ({
    askQuestion: builder.mutation({
      query: (body) => ({
        url: `vocal`,
        method: 'POST',
        body,
      }),
    }),
    getAudio: builder.mutation({
      query: (body) => ({
        url: `vocal/audio`,
        method: 'POST',
        body,
      }),
    }),
    askEggedQuestion: builder.mutation({
      query: (body) => ({
        url: `vocal/egged_answer`,
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const { useAskQuestionMutation, useGetAudioMutation, useAskEggedQuestionMutation } = vocalApi;
