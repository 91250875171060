import React from 'react';
import currentUser from '../../HOCs/currentUser.hoc';
import TTSComponent from './local_tts_training.component';

import './local_call.css';

const LocalTTSPage = () => {
  return (
    <div className="main-content mt-5">
      <div className="row text-muted"><h3>Local TTS Sentence Training</h3></div>
      <div className="row bg-white p-2 h90p">
        <TTSComponent />
      </div>
      <div className="empty-bottom-block" />
    </div>
  );
};

export default currentUser(LocalTTSPage);
