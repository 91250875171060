import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import currentUser from '../../HOCs/currentUser.hoc';
import { 
  useGetCampaignsQuery, 
  useDeleteCampaignMutation, 
  useCreateCampaignMutation,
} from '../../services/campaingApi';
import CampaignTable from './campaignTable.component';
import ConfirmationModal from '../../common/modals/confirmation.modal';
import AddCampaignModal from '../../common/modals/addCampaignModal';
import { AddFileIcon } from '../../common/icons';

import { apiRoot } from '../../config';
import { getAccessToken } from '../../reducers/authReducer';

import './campaign.css';

const CampaignPage = ({ currentUser }) => {
  const history = useHistory();
  const accessToken = useSelector(getAccessToken);

  const { data, isLoading: isCampaignsLoading, refetch } = useGetCampaignsQuery(null, { refetchOnMountOrArgChange: true });
  const [deleteCampaign] = useDeleteCampaignMutation();
  const [createCampaign] = useCreateCampaignMutation();

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [campaignDeletingID, setCampaignDeletingID] = useState(null);
  const [campaignDeletingError, setCampaignDeletingError] = useState(null);

  const [showAddModal, setShowAddModal] = useState(false);
  const [addCampaignError, setAddCampaignError] = useState(null);

  const onView = (campaignID) => {
    history.push(`/campaing/item/${campaignID}`);
  };

  const onDelete = (campaignID) => {
    setCampaignDeletingID(campaignID);
    setShowDeleteModal(true);
    setCampaignDeletingError(null);
  };

  const onHideDelete = () => {
    setCampaignDeletingID(null);
    setShowDeleteModal(false);
    setCampaignDeletingError(null);
  };

  const onConfirmDelete = async () => {
    if (campaignDeletingID) {
      const result = await deleteCampaign(campaignDeletingID);
      if (!result.error) {
        await refetch();
        onHideDelete();
      } else {
        setCampaignDeletingError(result?.error?.data?.message);
      }
    } else {
      onHideDelete();
    }
  };

  const handleDownload = async (campaign) => {
    if (!campaign?.id) return;
    try {
      // Формируем имя файла, например:
      const filename = `${campaign.id}-campaign-${campaign.name}.csv`;
      // Выполняем запрос напрямую через fetch:
      const response = await fetch(`${apiRoot}campaing/download/${campaign.id}`, {
        headers: {
          Authorization: accessToken,
        },
      });
      if (response.status === 200) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        window.URL.revokeObjectURL(url);
      } else {
        console.error('Error downloading campaign file: Status', response.status);
      }
    } catch (error) {
      console.error('Error downloading campaign file:', error);
    }
  };

  const onConfirmCreate = async (formData) => {
    try {
      const result = await createCampaign(formData).unwrap();
      if (result) {
        await refetch();
        setShowAddModal(false);
        return true;
      }
    } catch (err) {
      setAddCampaignError(err?.data?.message || 'Error creating campaign');
      return false;
    }
  };

  return (
    <div className="main-content mt-5">
      <div className="row text-muted">
        <h3>Campaign Management <span className="fs-6">List</span></h3>
      </div>
      <div className="row bg-white">
        <button 
          className="btn btn-success w200 ms-2 mt-3 mb-2" 
          onClick={() => setShowAddModal(true)}
        >
          <AddFileIcon /> Add Campaign
        </button>
      </div>
      <CampaignTable
        campaigns={data}
        isLoading={isCampaignsLoading}
        onView={onView}
        onDelete={onDelete}
        onDownload={handleDownload}
        currentUser={currentUser}
      />
      <ConfirmationModal
        show={showDeleteModal}
        onHide={onHideDelete}
        onConfirm={onConfirmDelete}
        title="Delete Campaign?"
        description={`Are you sure you want to delete Campaign (ID=${campaignDeletingID})?`}
        error={campaignDeletingError}
      />
       <AddCampaignModal
        show={showAddModal}
        onHide={() => setShowAddModal(false)}
        onConfirm={onConfirmCreate}
        error={addCampaignError}
      />
    </div>
  );
};

export default currentUser(CampaignPage);
