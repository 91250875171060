import React from 'react';

const DetailsWrapper = ({ children, title, label, blockTitle = null, blockButton = null, className = '' }) => (
  <div className={`user-view-container main-content mt-5 ${className}`}>
    <div className="row text-muted"><h3>{title} <span className="fs-6">{label}</span></h3></div>
    <div className="row d-block bg-white flex-grow-1 t-a-justify">
      <div className="border-bottom h55 pt-3 pb-2 mb-2 text-muted">
        {blockTitle && <h5>{blockTitle}</h5>}
        {blockButton && blockButton}
      </div>
      {children}
    </div>
  </div >
);

export default DetailsWrapper;