import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { InfoIcon } from '../icons';

const ICTitle = (template) => `For incoming calls in this modal you see the information that was collected using propmt (field "additional data") from the "${template}" template `;

const CallSummaryModal = ({ show, call, onHide }) => {
  return (
    <Modal show={show} onHide={onHide} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{call?.type === 'incomming-call' ? <div className='d-flex'> Call data <div className='ms-1' title={ICTitle(call?.template_title)}><InfoIcon /></div></div> : 'Call Summary'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p><span className="text-muted">Call ID:</span> {call?.id || 1}</p>
        {call?.type === 'incomming-call'
        ? <p><span className="text-muted">From:</span>{call?.callerPhone || '---'} <span className="text-muted">To:</span>{call?.phone || '---'}</p>
        :<p><span className="text-muted">Phone:</span> {call?.phone || '---'}</p>}
        <div className="large-text-container mh-150">{call?.summary ? call?.summary : 'There is no summary for this call.'}</div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide} autoFocus>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CallSummaryModal;
