import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';
import { CallIcon, InfoIcon, UploadIcon, DownloadIcon } from '../../common/icons';
import languages from './languages.json'
import { apiRoot } from '../../config';
import { useInitBlandBatchCallMutation, useGetConvPathwayQuery } from '../../services/callApi';
import { useGetTemplatesListQuery } from '../../services/templateApi';
import { getAccessToken } from '../../reducers/authReducer';
import { useGetCampaignsQuery } from '../../services/campaingApi';

const statusesList = [
  { id: null, title: 'not selected' },
  { id: 'busy', title: 'busy' },
  { id: 'completed', title: 'completed' },
  { id: 'failed', title: 'failed' },
  { id: 'in-progress', title: 'in progress' },
  { id: 'no answer', title: 'no answer' },
  { id: 'requested', title: 'requested' },
];

const BlandBatchCallComponent = () => {
  const accessToken = useSelector(getAccessToken);

  const [csvFile, setCsvFile] = useState(null);
  const [fileName, setFileName] = useState("");

  const [voice, setVoice] = useState({ id: 'f', title: 'Female' });
  const [supportedLanguages] = useState(languages);
  const [defaultLanguage, setDefaultLanguage] = useState(JSON?.parse(localStorage.getItem('call-language')) || languages[0]);
  const [template, setTemplate] = useState({});
  const [campaign, setCampaign] = useState(null);
  const [campaignStatus, setCampaignStatus] = useState({ id: null, title: 'not selected' });
  const [callData, setCallData] = useState(false);
  const [callError, setCallError] = useState(false);
  const [convPathway, setConvPathway] = useState({ id: null, title: 'not selected' });
  const [convPathways, setConvPathways] = useState([]);
  const [transferPhone, setPhone] = useState('');

  const { data: convData } = useGetConvPathwayQuery(null, { refetchOnMountOrArgChange: true });

  const [initBatchCall, { isLoading, isError }] = useInitBlandBatchCallMutation();
  const { data: templates } = useGetTemplatesListQuery(null, { refetchOnMountOrArgChange: true });

  const { data: campaigns } = useGetCampaignsQuery(null, { refetchOnMountOrArgChange: true });

  useEffect(() => {
    if (templates?.length && Object.keys(template).length === 0) {
      setTemplate(templates[0]);
    }
  }, [templates, template]);

  useEffect(() => {
    if (convData) {
      setConvPathways([{ id: null, title: 'not selected' }, ...convData]);
    }
  }, [convData]);


  const onChangeLanguage = (e) => {
    const { value } = e.target;
    const { id } = e.target.selectedOptions[0];
    setCallData(false);
    setDefaultLanguage({ name: id, lang: value });
    localStorage.setItem('call-language', JSON.stringify({ name: id, lang: value }));
  }

  const onChangeTemplate = (e) => {
    setCallData(false);
    const { id } = e.target.selectedOptions[0];
    const newTemplate = templates.find(t => t?.id == id);
    setTemplate(newTemplate);
  }

  const onChangeCampaign = (e) => {
    setCallData(false);
    const { id } = e.target.selectedOptions[0];
    if (id !== 0) {
      const newTemplate = campaigns.find(t => t?.id == id);
      setCampaign(newTemplate);
    } else {
      setCampaign(null);
      setCampaignStatus({ id: null, title: 'not selected' });
    }
  }

  const onChangeStatus = (e) => {
    const { value } = e.target;
    const { id } = e.target.selectedOptions[0];

    setCampaignStatus({ id: id, title: value });
  }

  const onChangeConvPathway = (e) => {
    const { id } = e.target.selectedOptions[0];
    const obj = convPathways?.find(item => item?.id === (id || null));
    setConvPathway(obj);
  }

  const onChangeVoice = (e) => {
    setCallData(false);
    const { value } = e.target;
    const { id } = e.target.selectedOptions[0];

    setVoice({ id: id, title: value });
  }

  const onChangeTPhone = (e) => {
    setPhone(e.target.value)
  }

  const handleFileChange = async (event) => {
    if (!event?.target?.files) {
      console.error('No file selected');
      return;
    }
    setCallData(false);
    setCsvFile(event.target?.files[0]);
    setFileName(event.target?.files[0]?.name || '');
  };

  const getFileName = (filePath) => {
    const parts = filePath?.split(/[/\\]/);
    return parts[parts?.length - 1];
  }

  const handleDownload = async () => {
    if (!template?.id) { return }

    try {
      const configFileName = getFileName(template?.config_file);
      const response = await fetch(`${apiRoot}templates/download-config/${template.id}`, {
        headers: {
          Authorization: accessToken
        }
      });
      if (response.status === 200) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', configFileName);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      }
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  };

  const handleBatchCall = async () => {
    if ((!csvFile && !campaign?.id) || !template || !defaultLanguage) {
      return;
    }
    setCallData(false);
    try {
      const formData = new FormData();
      formData.append('language', defaultLanguage.lang);

      if (!!convPathway?.id) {
        formData.append('pathway_id', convPathway?.id);
      } else {
        formData.append('template_id', template.id);
      }

      if (campaign?.id) {
        formData.append('campaing_id', campaign.id);

        if (campaignStatus?.id) {
          formData.append('campaing_status', campaignStatus.title);
        }
      } else {
        formData.append('file', csvFile);
      }

      if (transferPhone?.length) {
        formData.append('transfer_number', transferPhone);
      }

      const response = await initBatchCall(formData);
      if (!response.error) {
        setCallError(false);
        setCallData(response?.data?.message);
      } else {
        if (response?.error?.status === 400) {
          setCallError(response?.error?.data?.message);
        } else {
          setCallError('Something went wrong... Please try again later');
        }
      }
    } catch (error) {
      console.error('Error sending phone to API:', error);
    }
  }


  return (
    <div className="speech-container">
      <div className="bot-select mt-3 mb-3">
        <span>Template:</span>
        <select className="form-select w243 bot-select ms-2" onChange={onChangeTemplate}>
          {templates?.map(temp => (
            <option key={temp.id} id={temp.id} value={temp.title}>
              {temp.title}
            </option>
          ))}
        </select>
      </div>
      <div className="mb-3">
        <span>Conversational Pathways:</span><br />
        <select className="form-select w243 bot-select ms-6" onChange={onChangeConvPathway} value={convPathway?.title}>
          {convPathways?.map(convP => (
            <option key={convP.id} id={convP.id} value={convP.title}>
              {convP.title}
            </option>
          ))}
        </select>
      </div>
      <div className="bot-select mt-3 mb-3">
        <span>Campaign:</span>
        <select className="form-select w235 bot-select ms-2" onChange={onChangeCampaign}>
          <option key={0} id={0} value={'not selected'}>
            not selected
          </option>
          {campaigns?.map(temp => (
            <option key={temp.id} id={temp.id} value={temp.name}>
              {temp.name}
            </option>
          ))}
        </select>
      </div>
      {campaign?.id ? (
        <div className="bot-select mb-3">
          <span>Campaign Status:</span>
          <select className="form-select w185 bot-select ms-2" onChange={onChangeStatus}>
            {statusesList?.map(temp => (
              <option key={temp.id} id={temp.id} value={temp.title}>
                {temp.title}
              </option>
            ))}
          </select>
        </div>
      ) : null}
      <div className="bot-select ">
        <label htmlFor="fileInput" className="btn btn-success w200 ms-2 mt-2 mb-1">
          <><UploadIcon /> Upload CSV</>
          <input
            id="fileInput"
            type="file"
            accept=".csv"
            style={{ display: 'none' }}
            onChange={handleFileChange}
          />
        </label>
        <button
          className="btn btn-primary w200 ms-2 mt-2 mb-1"
          onClick={handleDownload}
          disabled={Object.keys(template).length === 0}
        >
          <DownloadIcon /> Download CSV
        </button>
      </div>
      <div className="csv-file-label">{fileName ? <>File: {fileName}</> : ''}</div>
      <div className="bot-select mb-3 mt-3"><span>Language:</span>
        <select className="form-select w240 bot-select ms-2" onChange={onChangeLanguage} value={defaultLanguage?.lang}>
          {supportedLanguages?.map(lang => (
            <option key={lang.lang} id={lang.name} value={lang.lang}>
              {lang.name}
            </option>
          ))}
        </select>
      </div>
      <div className="bot-select">
        <span>Voice:</span>
        <select className="form-select w271 bot-select ms-2" onChange={onChangeVoice}>
          {[{ id: 'f', title: 'Female' }]?.map(voice => (
            <option key={voice.id} id={voice.id} value={voice.title}>
              {voice.title}
            </option>
          ))}
        </select>
      </div>
      <div className="bot-select mb-3 mt-3">
        <span>Transfer Phone:</span>
        <input
          type="phone"
          className={`form-control ms-2 mt-1 w202`}
          placeholder="Transfer phone number"
          value={transferPhone}
          onChange={onChangeTPhone}
        />
      </div>
      <div className=" mt-3">
        <Button
          variant="primary"
          className="call-button"
          onClick={handleBatchCall}
          disabled={!csvFile && !campaign?.id}
        >
          <CallIcon className="mb-1 call-button-icon" /> {isLoading ? <div className="d-inline-flex">Requested <div className="call-dot-container">
            <div className="call-dot"></div>
            <div className="call-dot"></div>
            <div className="call-dot"></div>
          </div></div> : ' Request a calls'}
        </Button>
      </div>
      <br />
      {callData ? <><br /><p>{callData}</p></> : null}
      {isError ? <><br /><p className="alert-text"><span>Error: </span>{callError}</p></> : null}
      <br />
      <div className="p-0 mb-2">
        <p><InfoIcon /> You can select Template or Conversational Pathways. If you select a Conversational Pathways, it will override the Template field.</p>
        <p><InfoIcon /> You can choose Campaign or Upload file. If you choose Campaign, it will override the file you uploaded. </p>
        <p><InfoIcon /> optional: If you select Campaign, you can also select the status of items for that campaign (if you have previously made calls for that Campaign). </p>
        <p><InfoIcon /> Download a CSV file. The first row should be the headers of the table. Do not change the headers. The column containing phone numbers must have a header labeled as "phone".</p>
        <p><InfoIcon /> Enter data in each row for each header. Phone should contains +, country code and number. Upload the file with data.</p>
        <p><InfoIcon /> Select other settings and click the "Request a calls" button.</p>
      </div>
    </div>
  );
};

export default BlandBatchCallComponent;
