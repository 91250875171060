import React from 'react';
import TableWrapper from '../../common/table/tableWrapper.common';
import TableHeader from '../../common/table/tableHeader.common';
import { ShowIcon, EditIcon, NoEditIcon, DeleteIcon, DownloadFileIcon } from '../../common/icons';
import formatDateTime from '../../utils/formatDateTime';

function TemplatesTable({ templates = [], isLoading, onView, onEdit, onDelete, onDownload, currentUser }) {
  const currentUserRole = currentUser?.role?.name;
  const isAdmin = (currentUserRole?.includes('admin') || currentUserRole?.includes('superadmin'));
  const isCompanyAdmin = currentUserRole?.includes('companyadmin');

  const userHeaders = ['ID', 'Title', 'Upload User', 'Upload Date', 'Edit User',  'Edit Date', 'Phone', 'Actions'];
  const companAdminHeaders = ['ID', 'Title', 'Company', 'Upload User', 'Upload Date', 'Edit User',  'Edit Date', 'Phone', 'Actions'];
  const headers = isCompanyAdmin ? companAdminHeaders : userHeaders;

  const templateTableBody = () => (
    <tbody>
      {templates?.map(template => (
        <tr key={template.id} style={{ verticalAlign: 'middle' }}>
          <td>{template?.id}</td>
          <td>{template?.title}</td>
          {isCompanyAdmin ? <td>{template?.company_name}</td> : null}
          <td>{template?.upload_user_name}</td>
          <td>{formatDateTime(template?.upload_date)}</td>
          <td>{template?.edit_user_name}</td>
          <td>{formatDateTime(template?.updated_date)}</td>
          <td>{template?.phone}</td>
          <td>
            <div className="f-t-actions mw-135">
              <div className="cursor-p" onClick={() => onView(template.id)} title="View"><ShowIcon /></div>
              <div className="cursor-p" onClick={() => onDownload(template)} title="Download" ><DownloadFileIcon /></div>
              {
                (template?.version === 1)
                  ? <div className="cursor-p" onClick={() => onEdit(template.id)} title="Edit"><EditIcon /></div>
                  : <div className="cursor-p" title="Edit"><NoEditIcon /></div>
              }
              
              {isAdmin && <div className="cursor-p" onClick={() => onDelete(template.id)} title="Delete"><DeleteIcon /></div>}
            </div>
          </td>
        </tr>
      ))}
    </tbody>
  );

  const tableLoader = () => (
    <tbody>
      <tr>
        <td colSpan={isCompanyAdmin ? 9 : 8} className="text-center">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </td>
      </tr>
    </tbody>
  );

  return (
    <TableWrapper>
      <TableHeader headers={headers} />
      {isLoading ? tableLoader() : templateTableBody()}
    </TableWrapper>
  );
}

export default TemplatesTable;