import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { UploadIcon } from '../../common/icons';

const AddCampaignModal = ({ show, onHide, onConfirm, error }) => {
  const [campaignName, setCampaignName] = useState('');
  const [csvFile, setCsvFile] = useState(null);
  const [fileName, setFileName] = useState('');

  const handleFileChange = (event) => {
    if (!event.target.files || event.target.files.length === 0) {
      console.error('No file selected');
      return;
    }
    setCsvFile(event.target.files[0]);
    setFileName(event.target.files[0].name);
  };

  const handleCreate = async () => {
    if (!csvFile) {
      alert('Please select a CSV file');
      return;
    }

    const formData = new FormData();
    formData.append('campaignFile', csvFile);
    formData.append('name', campaignName);

    const result = await onConfirm(formData);
    if (result) {
      setCampaignName('');
      setCsvFile(null);
      setFileName('');
    }
  };

  const handleClose = () => {
    setCampaignName('');
    setCsvFile(null);
    setFileName('');
    onHide();
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Add Campaign</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="campaignName">
            <Form.Label>Campaign Name</Form.Label>
            <Form.Control
              type="text"
              value={campaignName}
              onChange={(e) => setCampaignName(e.target.value)}
              placeholder="Enter campaign name"
            />
          </Form.Group>
          <Form.Group controlId="campaignCSV" className="mt-2">
            <Form.Label>Campaign File</Form.Label>
            <div className="bot-select">
              <label htmlFor="fileInput" className="btn btn-success w200">
                <><UploadIcon /> Upload CSV</>
                <input
                  id="fileInput"
                  type="file"
                  accept=".csv"
                  style={{ display: 'none' }}
                  onChange={handleFileChange}
                />
              </label>
            </div>
            <div className="csv-file-label">
              {fileName ? <>File: {fileName}</> : ''}
            </div>
          </Form.Group>
          {error && <div className="text-danger mt-2">{error}</div>}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>Cancel</Button>
        <Button variant="primary" onClick={handleCreate}>Create</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddCampaignModal;