const formatDateTime = (date) => {
  if (!date) return '---';

  const uploadDate = new Date(date);

  const year = uploadDate.getFullYear();
  const month = ('0' + (uploadDate.getMonth() + 1)).slice(-2);
  const day = ('0' + uploadDate.getDate()).slice(-2);
  const hours = ('0' + uploadDate.getHours()).slice(-2);
  const minutes = ('0' + uploadDate.getMinutes()).slice(-2);

  return `${year}-${month}-${day} ${hours}:${minutes}`;
}

export const formatDate = (date) => {
  if (!date) return '---';

  const uploadDate = new Date(date);

  const year = uploadDate.getFullYear();
  const month = ('0' + (uploadDate.getMonth() + 1)).slice(-2);
  const day = ('0' + uploadDate.getDate()).slice(-2);

  return `${year}-${month}-${day}`;
}

export const getDateForFileName = () => {
  const now = new Date(Date.now());

  const month = String(now.getMonth() + 1).padStart(2, '0');
  const day = String(now.getDate()).padStart(2, '0');
  const year = now.getFullYear();
  const hours = String(now.getHours()).padStart(2, '0');
  const minutes = String(now.getMinutes()).padStart(2, '0');

  const formattedDate = `${month}-${day}-${year}_${hours}-${minutes}`;

  return formattedDate;
}

export default formatDateTime;
