import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import currentUser from '../../HOCs/currentUser.hoc';
import SMSTable from './smsTable.component';
import { useGetSMSListQuery, useDeleteSMSConvMutation } from '../../services/smsApi';
import ConfirmationModal from '../../common/modals/confirmation.modal';
import { RefreshIcon } from '../../common/icons';

const SMSAdminPage = () => {
  const { data, isLoading, refetch } = useGetSMSListQuery(null, { refetchOnMountOrArgChange: true });
  const [deleteSMS] = useDeleteSMSConvMutation();
  const history = useHistory();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [smsDeletingID, setSMSDeletingID] = useState(false);
  const [smsDeletingError, setSMSDeletingError] = useState(false);

  const refreshList = async () => {
    await refetch();
  }

  const onView = (convID) => {
    history.push(`/sms/${convID}`);
  }

  const onDelete = (convID) => {
    setSMSDeletingID(convID);
    setShowDeleteModal(true);
    setSMSDeletingError(false);
  }

  const onDownload = async () => {
  }

  const onHideDelete = () => {
    setSMSDeletingID(false);
    setShowDeleteModal(false);
    setSMSDeletingError(false);
  }

  const onConfirmDelete = async () => {
    if (smsDeletingID) {
      const result = await deleteSMS(smsDeletingID);
      if (!result.error) {
        onHideDelete();
        await refetch();
      } else {
        setSMSDeletingError(result?.error?.data?.message);
      }
    } else {
      onHideDelete();
    }
  }

  return (
    <div className="main-content mt-5">
      <div className="row text-muted "><h3>SMS <span className="fs-6">List</span></h3></div>
      <div className="row bg-white">
        <button className="btn btn-success w200 ms-2 mt-2 mb-1" onClick={refreshList}>
          <RefreshIcon /> {isLoading ? 'Loading...' : 'Refresh list'}
        </button>
      </div>
      <SMSTable smsData={data} isLoading={isLoading} onView={onView} onDelete={onDelete} onDownload={onDownload} />
      <ConfirmationModal
        show={showDeleteModal}
        onHide={onHideDelete}
        onConfirm={onConfirmDelete}
        title="Delete SMS history?"
        description={`Are you sure you want to delete SMS history (Conversation ID: ${smsDeletingID}) ? The conversation associated with SMS will also be deleted.`}
        error={smsDeletingError}
      />
    </div>
  )
}

export default currentUser(SMSAdminPage);