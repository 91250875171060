import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import currentUser from '../../../HOCs/currentUser.hoc';
import DetailsWrapper from '../../../common/wrappers/detailsWrapper.component';
import { Form, Button } from 'react-bootstrap';
import { SaveIcon, CopyIcon } from '../../../common/icons';
import InputField from '../../../common/forms/input.form';
import VerificationStatusBar from '../verificationStatusBar';

import { useCheckTwilioMutation, useGetCompanyByIdQuery, useUpdateCompanyMutation } from '../../../services/companyApi';

const defaultCompanyState = {
  name: '',
  twilio_acount_sid: '',
  twilio_auth_token: '',
  twilio_number: '',
  sip_acount_sid: '',
  sip_auth_token: '',
  sip_number: '',
  sms_acount_sid: '',
  sms_auth_token: '',
  sms_number: '',
  transfer_number: '',
  sip_transfer_number: '',
};

const EditCompany = () => {
  const history = useHistory();
  const { companyID } = useParams();

  const { data: companyData, isLoading, isError, refetch } = useGetCompanyByIdQuery(companyID, { refetchOnMountOrArgChange: true });

  const [updateCompany, { isLoading: isCompanyLoading }] = useUpdateCompanyMutation();
  const [checkTwilio, { isLoading: isTwilioChecking }] = useCheckTwilioMutation();

  const [formData, setFormData] = useState(defaultCompanyState);

  useEffect(() => {
    if (companyData) {
      setFormData(companyData);
    }
  }, [companyData]);

  const [errors, setErrors] = useState({});

  const [twilioStatus, setTwilioStatus] = useState({ label: 'Verification of Twilio data', status: 'not_required' });
  const [sipStatus, setSipStatus] = useState({ label: 'Verification of SIP data', status: 'not_required' });
  const [smsStatus, setSmsStatus] = useState({ label: 'Verification of SMS data', status: 'not_required' });
  const [companyStatus, setCompanyStatus] = useState({ label: 'Update company', status: 'not_required' });

  const [showVerificationBar, setShowVerificationBar] = useState(false);

  const addButtonRef = useRef(null);

  useEffect(() => {
    if (showVerificationBar && addButtonRef.current) {
      addButtonRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [showVerificationBar]);

  const handleChange = e => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const validateForm = (data) => {
    const errors = {};

    if (!data.name?.trim()) {
      errors.name = 'Name is required';
    }

    const twilioFilled = [data?.twilio_acount_sid, data?.twilio_auth_token, data?.twilio_number].filter(v => v?.trim()).length;
    if (twilioFilled > 0 && twilioFilled < 3) {
      errors.twilio = 'If one Twilio field is filled, all three (SID, Token, Numbers) must be filled';
    }

    const sipFilled = [data?.sip_acount_sid, data?.sip_auth_token, data?.sip_number].filter(v => v?.trim()).length;
    if (sipFilled > 0 && sipFilled < 3) {
      errors.sip = 'If one SIP field is filled, all three (SID, Token, Numbers) must be filled';
    }

    const smsFilled = [data?.sms_acount_sid, data?.sms_auth_token, data?.sms_number].filter(v => v?.trim()).length;
    if (smsFilled > 0 && smsFilled < 3) {
      errors.sms = 'If one SMS field is filled, all three (SID, Token, Numbers) must be filled';
    }

    if (twilioFilled === 3 && !data.transfer_number?.trim()) {
      errors.transfer_number = 'Transfer Number is required when Twilio fields are all filled';
    }

    if (sipFilled === 3 && !data.sip_transfer_number?.trim()) {
      errors.sip_transfer_number = 'Sip Transfer Number is required when SIP fields are all filled';
    }

    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { created_at, id, is_deleted, userCount, ...dataForCheck } = formData;
    setShowVerificationBar(false);
    const validationErrors = validateForm(dataForCheck);
    if (Object.keys(validationErrors).length === 0) {
      const trimmedFormData = {};
      for (const key in dataForCheck) {
        trimmedFormData[key] = dataForCheck[key]?.trim ? dataForCheck[key].trim() : dataForCheck[key];
      }
      setErrors({});

      const twilioFilled = [trimmedFormData.twilio_acount_sid, trimmedFormData.twilio_auth_token, trimmedFormData.twilio_number].filter(v => v).length === 3;
      const sipFilled = [trimmedFormData.sip_acount_sid, trimmedFormData.sip_auth_token, trimmedFormData.sip_number].filter(v => v).length === 3;
      const smsFilled = [trimmedFormData.sms_acount_sid, trimmedFormData.sms_auth_token, trimmedFormData.sms_number].filter(v => v).length === 3;

      twilioFilled
        ? setTwilioStatus({ label: 'In progress: Verification of Twilio data', status: 'pending' })
        : setTwilioStatus({ label: 'Verification of Twilio data', status: 'not_required' });
      sipFilled
        ? setSipStatus({ label: 'Verification of SIP data', status: 'pending' })
        : setSipStatus({ label: 'Verification of SIP data', status: 'not_required' });
      smsFilled
        ? setSmsStatus({ label: 'Verification of SMS data', status: 'pending' })
        : setSmsStatus({ label: 'Verification of SMS data', status: 'not_required' });

      setCompanyStatus({ label: 'Updating company', status: 'pending' });

      setShowVerificationBar(true);

      if (twilioFilled) {
        setTwilioStatus({ label: 'In progress: Verification of Twilio data', status: 'in_progress' });
        const twilioCheckResult = await checkTwilio({
          accountSid: trimmedFormData.twilio_acount_sid,
          authToken: trimmedFormData.twilio_auth_token,
          phoneNumberToCheck: trimmedFormData.twilio_number
        });
        if (twilioCheckResult.data?.length) {
          setTwilioStatus({ label: `Twilio error: ${twilioCheckResult.data?.[0]?.message}`, status: 'error' });
          return;
        } else {
          setTwilioStatus({ label: 'Success: Verification of Twilio data', status: 'success' });
        }
      }
      if (sipFilled) {
        setSipStatus({ label: 'In progress: Verification of SIP data', status: 'in_progress' });
        const twilioCheckResult = await checkTwilio({
          accountSid: trimmedFormData.sip_acount_sid,
          authToken: trimmedFormData.sip_auth_token,
          phoneNumberToCheck: trimmedFormData.sip_number
        });
        if (twilioCheckResult.data?.length) {
          setSipStatus({ label: `SIP error: ${twilioCheckResult.data?.[0]?.message}`, status: 'error' });
          return;
        } else {
          setSipStatus({ label: 'Success: Verification of SIP data', status: 'success' });
        }
      }
      if (smsFilled) {
        setSmsStatus({ label: 'In progress: Verification of SMS data', status: 'in_progress' });
        const twilioCheckResult = await checkTwilio({
          accountSid: trimmedFormData.sms_acount_sid,
          authToken: trimmedFormData.sms_auth_token,
          phoneNumberToCheck: trimmedFormData.sms_number
        });
        if (twilioCheckResult.data?.length) {
          setSmsStatus({ label: `SMS error: ${twilioCheckResult.data?.[0]?.message}`, status: 'error' });
          return;
        } else {
          setSmsStatus({ label: 'Success: Verification of SMS data', status: 'success' });
        }
      }

      setCompanyStatus({ label: 'Updating company', status: 'in_progress' });
      const {
        created_at, id, is_deleted, userCount,
        ...companyData
      } = trimmedFormData;

      const updateCompanyResult = await updateCompany({ id: companyID, body: companyData });

      if (updateCompanyResult.error) {
        setCompanyStatus({ label: `Company error: ${updateCompanyResult?.error?.data?.error || 'Something went wrong, please try again later'}`, status: 'error' });
        return;
      }
      setCompanyStatus({ label: 'Success: Company update', status: 'success' });

      refetch();
      setShowVerificationBar(false);
      history.push('/companies');
    } else {
      setErrors(validationErrors);
    }
  };

  const handleCopyTwillioData = () => {
    setFormData(prev => ({
      ...prev,
      sip_acount_sid: prev.twilio_acount_sid,
      sip_auth_token: prev.twilio_auth_token,
      sip_number: prev.twilio_number,
      sip_transfer_number: prev?.transfer_number,
    }));
  };

  const handleCopySMSTwillioData = () => {
    setFormData(prev => ({
      ...prev,
      sms_acount_sid: prev.twilio_acount_sid,
      sms_auth_token: prev.twilio_auth_token,
      sms_number: prev.twilio_number,
    }));
  };

  const handleCopySMSSIPData = () => {
    setFormData(prev => ({
      ...prev,
      sms_acount_sid: prev.sip_acount_sid,
      sms_auth_token: prev.sip_auth_token,
      sms_number: prev.sip_number,
    }));
  };

  const EditButtons = () => (
    <div className="p-0 mb-2" ref={addButtonRef}>
      <Button variant="primary ms-3 w200" type="submit" onClick={handleSubmit}>
        <SaveIcon /> {(isCompanyLoading || isTwilioChecking) ? 'Loading...' : 'Update Company'}
      </Button>
      <Button variant="secondary ms-3 w100" onClick={() => history.push('/companies')}>
        Cancel
      </Button>
    </div>
  );

  const isTwilioRequired = !!formData.twilio_auth_token || !!formData.twilio_number || !!formData.twilio_acount_sid;
  const isSipRequired = !!formData.sip_auth_token || !!formData.sip_number || !!formData.sip_acount_sid;
  const isSmsRequired = !!formData.sms_acount_sid || !!formData.sms_acount_sid || !!formData.sms_number;

  if (isLoading) {
    return (
      <DetailsWrapper title="Company" label="Edit" blockTitle="Edit">
        <div>Loading...</div>;
      </DetailsWrapper>
    )
  }

  if (isError) {
    return (
      <DetailsWrapper title="Company" label="Edit" blockTitle="Edit">
        <div>Error fetching Company data</div>
        <Button variant="secondary ms-3 w100" onClick={() => history.push('/companies')}>
          Back
        </Button>
      </DetailsWrapper>
    )
  }

  return (
    <DetailsWrapper title="Company" label="Edit" blockTitle="Edit">
      <Form className="mb-6" onSubmit={handleSubmit}>
        <label className="mb-2"><span className="text-muted mb-0">Total users in the company:</span> {formData?.userCount || 0}</label>
        <InputField
          label="Company Name*"
          name="name"
          value={formData.name}
          onChange={handleChange}
          errorMessage={errors?.name}
          autoFocus
        />
        <hr></hr>
        <h6 className="mt-4">Twilio data</h6>
        <InputField
          label={`Twilio Account SID${isTwilioRequired ? "*" : ""}`}
          name="twilio_acount_sid"
          value={formData.twilio_acount_sid}
          onChange={handleChange}
          errorMessage={errors?.twilio}
        />
        <InputField
          label={`Twilio Auth Token${isTwilioRequired ? "*" : ""}`}
          name="twilio_auth_token"
          value={formData.twilio_auth_token}
          onChange={handleChange}
          errorMessage={errors?.twilio}
        />
        <InputField
          label={`Twilio Number${isTwilioRequired ? "*" : ""}`}
          name="twilio_number"
          value={formData.twilio_number}
          onChange={handleChange}
          errorMessage={errors?.twilio}
        />
        <InputField
          label={`Transfer Number${isTwilioRequired ? "*" : ""}`}
          name="transfer_number"
          value={formData.transfer_number}
          onChange={handleChange}
          errorMessage={errors?.transfer_number}
        />

        <hr></hr>
        <h6 className="mt-4">SIP data</h6>
        <Button variant="secondary ms-3 mb-1 w240" disabled={!formData.twilio_auth_token || !formData.twilio_number || !formData.twilio_acount_sid} onClick={handleCopyTwillioData}>
          <CopyIcon /> Copy from Twilio data
        </Button>
        <InputField
          label={`SIP Account SID${isSipRequired ? "*" : ""}`}
          name="sip_acount_sid"
          value={formData.sip_acount_sid}
          onChange={handleChange}
          errorMessage={errors?.sip}
        />
        <InputField
          label={`SIP Auth Token${isSipRequired ? "*" : ""}`}
          name="sip_auth_token"
          value={formData.sip_auth_token}
          onChange={handleChange}
          errorMessage={errors?.sip}
        />
        <InputField
          label={`SIP Number${isSipRequired ? "*" : ""}`}
          name="sip_number"
          value={formData.sip_number}
          onChange={handleChange}
          errorMessage={errors?.sip}
        />
        <InputField
          label={`SIP Transfer Number${isSipRequired ? "*" : ""}`}
          name="sip_transfer_number"
          value={formData.sip_transfer_number}
          onChange={handleChange}
          errorMessage={errors?.sip_transfer_number}
        />

        <hr></hr>
        <h6 className="mt-4">Twilio SMS data</h6>
        <Button variant="secondary ms-3 mb-1 w240" disabled={!formData.twilio_auth_token || !formData.twilio_number || !formData.twilio_acount_sid} onClick={handleCopySMSTwillioData}>
          <CopyIcon /> Copy from Twilio data
        </Button>
        <Button variant="secondary ms-3 mb-1 w240" disabled={!formData.sip_auth_token || !formData.sip_number || !formData.sip_acount_sid} onClick={handleCopySMSSIPData}>
          <CopyIcon /> Copy from SIP data
        </Button>
        <InputField
          label={`SMS Account SID${isSmsRequired ? "*" : ""}`}
          name="sms_acount_sid"
          value={formData.sms_acount_sid}
          onChange={handleChange}
          errorMessage={errors?.sms}
        />
        <InputField
          label={`SMS Auth Token${isSmsRequired ? "*" : ""}`}
          name="sms_auth_token"
          value={formData.sms_auth_token}
          onChange={handleChange}
          errorMessage={errors?.sms}
        />
        <InputField
          label={`SMS Number${isSmsRequired ? "*" : ""}`}
          name="sms_number"
          value={formData.sms_number}
          onChange={handleChange}
          errorMessage={errors?.sms}
        />

        {showVerificationBar && <hr></hr>}
        {showVerificationBar && (
          <VerificationStatusBar
            twilioStatus={twilioStatus}
            sipStatus={sipStatus}
            smsStatus={smsStatus}
            userStatus={{ label: 'Verification of User data', status: 'not_required' }}
            companyStatus={companyStatus}
            userCteateStatus={{ label: 'Create admin user', status: 'not_required' }}
          />
        )}
        <hr></hr>
      </Form>
      <EditButtons />
    </DetailsWrapper>
  );
};

export default currentUser(EditCompany);
