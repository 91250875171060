import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import currentUser from '../../HOCs/currentUser.hoc';
import CallTable from './callTable.component';
import { useGetCallsListQuery, useDeleteCallMutation } from '../../services/callApi';
import ConfirmationModal from '../../common/modals/confirmation.modal';
import CallSummaryModal from '../../common/modals/summary.modal';
import { RefreshIcon, SettingsIcon } from '../../common/icons';
import InputField from '../../common/forms/input.form';
import SelectField from '../../common/forms/select.form';

const CallsAdminPage = () => {
  const [deleteCall] = useDeleteCallMutation();
  const history = useHistory();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [callDeletingID, setCallDeletingID] = useState(false);
  const [callDeletingError, setCallDeletingError] = useState(false);
  const [showSummaryModal, setShowSummaryModal] = useState(false);
  const [callSummaryData, setCallSummaryData] = useState(null);

  const [page, setPage] = useState(Number(localStorage.getItem('page')) || 1);
  const [limit, setLimit] = useState(Number(localStorage.getItem('limit')) || 50);
  const [filters, setFilters] = useState(JSON.parse(localStorage.getItem('filters')) || {});
  const [sendFilters, setSendFilters] = useState(JSON.parse(localStorage.getItem('sendFilters')) || {});
  const [showFilters, setShowFilters] = useState((localStorage.getItem('showFilters') === 'true' ? true : false) || false);

  const { data: callsData, isLoading, refetch } = useGetCallsListQuery(
    { page, limit, ...sendFilters },
    { refetchOnMountOrArgChange: true }
  );

  useEffect(() => {
    localStorage.setItem('filters', JSON.stringify(filters));
    localStorage.setItem('sendFilters', JSON.stringify(sendFilters));
    localStorage.setItem('page', page);
    localStorage.setItem('limit', limit);
    localStorage.setItem('showFilters', showFilters);
  }, [filters, page, limit, sendFilters, showFilters]);

  useEffect(() => {
    const savedFilters = localStorage.getItem('filters');
    const savedPage = localStorage.getItem('page');
    const savedLimit = localStorage.getItem('limit');
    const savedIsOpen = localStorage.getItem('showFilters');
    const savedSendFilters = localStorage.getItem('sendFilters');

    if (savedFilters) {
      setFilters(JSON.parse(savedFilters));
    }

    if (savedPage) {
      setPage(Number(savedPage));
    }

    if (savedLimit) {
      setLimit(Number(savedLimit));
    }

    if (savedIsOpen) {
      setShowFilters(savedIsOpen === 'true' ? true : false);
    }

    if (savedSendFilters) {
      setSendFilters(JSON.parse(savedSendFilters));
    }
  }, []);

  const refreshList = async () => {
    await refetch();
  }

  const handleFilterChange = (e) => {
    setFilters({
      ...filters,
      [e.target.name]: e.target.value,
    });
  };

  const applyFilters = () => {
    const hasKeys = Object.keys(filters).length > 0;
    if (hasKeys) setSendFilters(filters);
  }

  const resetFilters = () => {
    const hasKeys = Object.keys(sendFilters).length > 0;
    if (hasKeys) setSendFilters({});
    setFilters({
      phone: '',
      status: '',
      start_time: ''
    });

  }

  const toggleFilters = () => setShowFilters(!showFilters);

  const onView = (callID) => {
    history.push(`/calls/${callID}`);
  }

  const onDownload = async (call) => {
    if (call?.record) {
      const response = await fetch(call?.record);

      if (response.ok) {
        const blob = await response.blob();
        const downloadUrl = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.download = `${call?.id}_${call?.phone}_record.mp3`;
        document.body.appendChild(link);
        link.click();
        link.remove();
      } else {
        console.log('Failed to download the file.');
      }
    }
  }

  const onDelete = (callID) => {
    setCallDeletingID(callID);
    setShowDeleteModal(true);
    setCallDeletingError(false);
  }

  const onHideDelete = () => {
    setCallDeletingID(false);
    setShowDeleteModal(false);
    setCallDeletingError(false);
  }

  const onConfirmDelete = async () => {
    if (callDeletingID) {
      const result = await deleteCall(callDeletingID);
      if (!result.error) {
        onHideDelete();
        await refetch();
      } else {
        setCallDeletingError(result?.error?.data?.message);
      }
    } else {
      onHideDelete();
    }
  }

  const onSummary = (call) => {
    setCallSummaryData(call);
    setShowSummaryModal(true);
  }

  const onHideSummary = () => {
    setShowSummaryModal(false);
    setCallSummaryData(null);
  }

  return (
    <div className="main-content mt-5">
      <div className="row text-muted "><h3>Calls <span className="fs-6">List</span></h3></div>
      <div className="row bg-white calls-header-block pb-1">
        <div className='refresh-block w271'>
          <button className="btn btn-success w200 ms-2 mt-2 mb-1 h40" onClick={refreshList}>
            <RefreshIcon /> {isLoading ? 'Loading...' : 'Refresh list'}
          </button>
          <div className="settinsg-block" onClick={toggleFilters}><SettingsIcon /></div>
        </div>
        <div className='pagination-block'>
          <nav aria-label="Page navigation" className='h40 w180 ms-2 mt-2 mb-1'>
            <ul className="pagination h40">
              <li className={`page-item ${!callsData?.hasPrevPage ? 'disabled' : ''}`}
                onClick={(e) => {
                  if (callsData?.hasPrevPage) {
                    setPage(page - 1);
                  }
                }}
              >
                <a className="page-link" href="#" tabIndex="-1"><span aria-hidden="true" style={{ outline: 'none', boxShadow: 'none' }}
                >&laquo;</span></a>
              </li>
              <li className="page-item disabled">
                <div className="page-link" href="#">Total: {callsData?.totalCount}</div>
              </li>
              <li className="page-item" style={{ outline: 'none', boxShadow: 'none' }}
                onClick={(e) => {
                  if (callsData?.hasNextPage) {
                    setPage(page + 1);
                  }
                }}
              >
                <a className={`page-link  ${!callsData?.hasNextPage ? 'disabled' : ''}`} href="#"><span aria-hidden="true" style={{ outline: 'none', boxShadow: 'none' }}
                >&raquo;</span></a>
              </li>
            </ul>
          </nav>
        </div>
      </div>

      <div className={`row filter-block ${showFilters ? 'show' : ''}`}>
        <div className="filter-container">
          <div className='filter-item'>
            <InputField
              label="Phone number"
              name="phone"
              value={filters.phone}
              onChange={handleFilterChange}
            />
          </div>
          <div className='filter-item'>
            <SelectField
              label="Status"
              name="status"
              value={filters.status}
              options={[{
                "id": "",
                "name": ''
              },
              {
                "id": "busy",
                "name": "busy"
              },
              {
                "id": "completed",
                "name": "completed"
              },
              {
                "id": "in-progress",
                "name": "in-progress"
              },
              {
                "id": "failed",
                "name": "failed"
              },
              ]}
              onChange={handleFilterChange}
            />
          </div>
          <div className='filter-item'>
            <InputField
              label="Start date:"
              name="start_time"
              value={filters.start_time}
              onChange={handleFilterChange}
            />
          </div>
          <div className='filter-button-item'>
            <button className="btn btn-success w120 ms-2 mt-2 mb-1 h40" onClick={applyFilters}>
              Apply filters
            </button>
          </div>
          <div className='filter-button-item'>
            <button className="btn btn-secondary w120 ms-2 mt-2 mb-1 h40" onClick={resetFilters}>
              Reset filters
            </button>
          </div>
        </div>
      </div>

      <CallTable calls={callsData?.data} isLoading={isLoading} onView={onView} onDelete={onDelete} onDownload={onDownload} onSummary={onSummary} />
      <ConfirmationModal
        show={showDeleteModal}
        onHide={onHideDelete}
        onConfirm={onConfirmDelete}
        title="Delete Call?"
        description={`Are you sure you want to delete Call (ID=${callDeletingID}) ? The conversation associated with call will also be deleted.`}
        error={callDeletingError}
      />

      <CallSummaryModal 
        show={showSummaryModal}
        call={callSummaryData}
        onHide={onHideSummary}
      />
    </div>
  )
}

export default currentUser(CallsAdminPage);