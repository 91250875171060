import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import currentUser from '../../HOCs/currentUser.hoc';
import UserTable from './userTable.component';
import { useGetUsersListQuery } from '../../services/userApi';
import ConfirmationModal from '../../common/modals/confirmation.modal';
import { useDeleteUserMutation } from '../../services/userApi';
import { AddUserIcon } from '../../common/icons';

import './user.css';

const UserAdminPage = ({ currentUser }) => {
  const currentUserRole = currentUser?.role?.name;
  const isCompanyAdmin = currentUserRole?.includes('companyadmin');

  const { data, isLoading: isUsersLoading, refetch } = useGetUsersListQuery(null, { refetchOnMountOrArgChange: true });
  const [deleteUser] = useDeleteUserMutation();
  const history = useHistory();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [userDeletingID, setUserDeletingID] = useState(false);
  const [userDeletingError, setUserDeletingError] = useState(false);

  const onView = (userID) => {
    history.push(`/users/view/${userID}`);
  }

  const onEdit = (userID) => {
    history.push(`/users/edit/${userID}`);
  }

  const onDelete = (userID) => {
    setUserDeletingID(userID);
    setShowDeleteModal(true);
    setUserDeletingError(false);
  }

  const onHideDelete = () => {
    setUserDeletingID(false);
    setShowDeleteModal(false);
    setUserDeletingError(false);
  }

  const onConfirmDelete = async () => {
    if (currentUser?.id === userDeletingID) {
      return setUserDeletingError("You can't delete yourself");
    }
    if (userDeletingID) {
      const result = await deleteUser({ userID: userDeletingID });
      if (!result.error) {
        await refetch();
        onHideDelete();

      } else {
        setUserDeletingError(result?.error?.data?.message);
      }
    } else {
      onHideDelete();
    }
  }

  return (
    <div className="main-content mt-5">
      <div className="row text-muted "><h3>Users <span className="fs-6">List</span></h3></div>
      <div className="row bg-white">
        {!isCompanyAdmin && <button className="btn btn-success w200 ms-2 mt-2 mb-1" onClick={() => history.push('/users/add')}>
          <AddUserIcon /> Add user
        </button>}
      </div>
      <UserTable users={data} isLoading={isUsersLoading} onView={onView} onEdit={onEdit} onDelete={onDelete} isCompanyAdmin={isCompanyAdmin} />
      <ConfirmationModal
        show={showDeleteModal}
        onHide={onHideDelete}
        onConfirm={onConfirmDelete}
        title="Delete User?"
        description={`Are you sure you want to delete User (ID=${userDeletingID}) ?`}
        error={userDeletingError}
      />
    </div>
  )
}

export default currentUser(UserAdminPage);