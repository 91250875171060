// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.large-text-container {
  overflow: auto;
  white-space: pre-wrap;
  word-wrap: break-word;
  width: 100%;
  max-height: 400px;
  border: 1px solid #ccc;
  padding: 10px;
  min-width: 400px;
}

.table-wrapper table {
  border-collapse: separate;
  border-spacing: 0;
}

.table-sticky-col {
  position: sticky;
  right: 0;
  z-index: 10;
  border-left: 1px solid rgba(0, 0, 0, 0.175);
  min-width: 100px;
}

.table-item {
  white-space: nowrap;
}

.table-item-id {
  width: 55px;
  min-width: 55px;
}

.table-item-width {
  width: 200px;
  min-width: 200px;
  max-width: 200px;
}

.form-control-tbl-padding {
  padding: 0 10px !important;
  caret-color: #333333 !important;
}

.mt--8 {
  margin-top: -8px;
}

`, "",{"version":3,"sources":["webpack://./src/components/campaign/campaign.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,qBAAqB;EACrB,qBAAqB;EACrB,WAAW;EACX,iBAAiB;EACjB,sBAAsB;EACtB,aAAa;EACb,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;EACzB,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;EAChB,QAAQ;EACR,WAAW;EACX,2CAA2C;EAC3C,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,eAAe;AACjB;;AAEA;EACE,YAAY;EACZ,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,0BAA0B;EAC1B,+BAA+B;AACjC;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".large-text-container {\r\n  overflow: auto;\r\n  white-space: pre-wrap;\r\n  word-wrap: break-word;\r\n  width: 100%;\r\n  max-height: 400px;\r\n  border: 1px solid #ccc;\r\n  padding: 10px;\r\n  min-width: 400px;\r\n}\r\n\r\n.table-wrapper table {\r\n  border-collapse: separate;\r\n  border-spacing: 0;\r\n}\r\n\r\n.table-sticky-col {\r\n  position: sticky;\r\n  right: 0;\r\n  z-index: 10;\r\n  border-left: 1px solid rgba(0, 0, 0, 0.175);\r\n  min-width: 100px;\r\n}\r\n\r\n.table-item {\r\n  white-space: nowrap;\r\n}\r\n\r\n.table-item-id {\r\n  width: 55px;\r\n  min-width: 55px;\r\n}\r\n\r\n.table-item-width {\r\n  width: 200px;\r\n  min-width: 200px;\r\n  max-width: 200px;\r\n}\r\n\r\n.form-control-tbl-padding {\r\n  padding: 0 10px !important;\r\n  caret-color: #333333 !important;\r\n}\r\n\r\n.mt--8 {\r\n  margin-top: -8px;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
