import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { VocalIcon, InfoIcon } from '../../common/icons';
import { useAskEggedQuestionMutation } from '../../services/vocalApi';
import { useUpdateCallMutation } from '../../services/callApi';

const SpeechToText = () => {
  const [transcript, setTranscript] = useState('');
  const [recognition, setRecognition] = useState(null);
  const [recording, setRecording] = useState(false);
  const [waitingResponse, setWaitingResponse] = useState(false);
  const [answer, setAnswer] = useState(false);
  const [lastTranscriptChange, setLastTranscriptChange] = useState(null);
  const [history, addToHistory] = useState([]);
  const [call, setCall] = useState(false);


  const [getAnswer, { isError: isAnswerError }] = useAskEggedQuestionMutation();
  const [updateCall] = useUpdateCallMutation();

  useEffect(() => {
    const currentCall = call;

    const cleanup = () => {
      completeCall(currentCall);
    };

    return cleanup;
  }, [call]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      completeCall(call);
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [call]);

  useEffect(() => {
    return () => {
      window.speechSynthesis.onvoiceschanged = null;
    };
  }, []);

  useEffect(() => {
    let timer;

    const sendTextAfterDelay = () => {
      clearTimeout(timer);
      if (Date.now() - lastTranscriptChange > 1500) {
        sendTextToAPI();
      } else {
        timer = setTimeout(sendTextAfterDelay, 1500 - (Date.now() - lastTranscriptChange));
      }
    };

    if (recording && transcript.trim() !== '') {
      sendTextAfterDelay();
    }

    return () => clearTimeout(timer);
  }, [transcript, recording, lastTranscriptChange]);

  const handleTranscriptChange = () => {
    setLastTranscriptChange(Date.now());
  };

  const startSpeechRecognition = () => {
    const recognition = new window.webkitSpeechRecognition();
    recognition.continuous = true;
    recognition.interimResults = true;
    // recognition.lang = 'en-US';
    recognition.lang = 'he-IL';

    recognition.onstart = () => {
      console.log('Speech recognition started');
      setRecording(true);
    };

    recognition.onresult = event => {
      const interimTranscript = Array.from(event.results)
        .map(result => result[0].transcript)
        .join('');
      setTranscript(interimTranscript);
      handleTranscriptChange();
    };

    recognition.onerror = event => {
      if (event.error === 'no-speech') {
        startSpeechRecognition();
      }
    };

    recognition.onend = () => {
      console.log('Speech recognition ended');
      recognition.stop();
    };

    recognition.start();
    setRecognition(recognition);
  };

  const stopSpeechRecognition = () => {
    if (recognition) {
      recognition.stop();
      setRecording(false);
    }
  };

  const handleClick = async () => {
    if (recording) {
      addToHistory([]);
      stopSpeechRecognition();
      setCall(false);
    } else {
      startSpeechRecognition();
    }
  };


  const sendTextToAPI = async () => {
    if (!transcript.trim()) return;

    setAnswer(false);
    stopSpeechRecognition();
    setWaitingResponse(true);

    const historyArray = [...history];

    try {
      const getAnswerReqData = {
        history: [...historyArray, { role: 'user', content: transcript }],
      };
      if (!!call) getAnswerReqData.callID = call;
      
      const response = await getAnswer(getAnswerReqData);

      historyArray.push({ role: 'user', content: transcript });

      if (!response.error) {
        const { text, audio, callID } = response.data;

        setCall(callID);
        historyArray.push({ role: 'assistant', content: `${text}` });

        let promiseAudio = playAudio(audio, text);
        await promiseAudio;
      }
    } catch (error) {
      console.error('Error sending text to API:', error);
    } finally {
      setTranscript('');
      startSpeechRecognition();
      if (historyArray?.length > 9) {
        addToHistory(historyArray.slice(-9));
      } else {
        addToHistory(historyArray);
      }
      setWaitingResponse(false);
    }
  };

  const playAudio = async (audioData, text, isAddText = false) => {
    try {
      const audioBlob = new Blob([Uint8Array.from(atob(audioData), c => c.charCodeAt(0))], { type: 'audio/mpeg' });
      const audioUrl = URL.createObjectURL(audioBlob);

      const audioElement = new Audio(audioUrl);

      audioElement.addEventListener('loadedmetadata', () => { });

      await new Promise((resolve, reject) => {
        audioElement.addEventListener('ended', resolve);
        audioElement.addEventListener('error', reject);
        audioElement.addEventListener('canplay', () => {
          setTimeout(() => {
            if (isAddText) {
              setAnswer(prevAnswer => prevAnswer + '\n' + text);
            } else {
              setAnswer(text);
            }
            audioElement.play()
              .catch(error => {
                console.error('Error starting audio playback:', error);
              });
          }, !isAddText ? 500 : 0);
        });
      });

    } catch (error) {
      console.error('Error starting audio playback:', error);
    }
  };

  const completeCall = async (currentCall) => {
    if (currentCall) {
      await updateCall({
        callID: call, body: {
          status: 'completed',
          end_time: new Date().toISOString().slice(0, 19).replace('T', ' ')
        }
      });
    }
  }

  return (
    <div className="speech-container">
      <div className="speech-block mt-4">
        <Button
          variant={recording ? "danger" : "primary"}
          className={`rounded-circle microphone-button ${recording ? 'recording' : ''}`}
          onClick={handleClick}
          disabled={waitingResponse}
        >
          <VocalIcon width="25px" height="25px" className="" />

        </Button>
        {waitingResponse && <div className="wave-container">
          <div className="wave"></div>
          <div className="wave-bottom"></div>
        </div>}
      </div>
      <br />
      <div>
        <p><span>Question: </span>{transcript}</p>
        <p className="d-flex"><span>Answer: </span><span className='answ-block'>{answer ? answer : ''}</span></p>
        {isAnswerError ? <p className="alert-text"><span>Error: </span>Something went wrong... Please repeat your question</p> : null}
        <br />
      </div>
      <div className="speech-info-block p-0 mb-2">
        <p><InfoIcon /> To get started, click on the blue microphone button and ask a question.</p>
        <p><InfoIcon /> To finish, click on the red microphone button.</p>
      </div>
    </div>
  );
};

export default SpeechToText;
