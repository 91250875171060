import React from 'react';
import TableWrapper from '../../common/table/tableWrapper.common';
import TableHeader from '../../common/table/tableHeader.common';
import { ShowIcon, DeleteIcon, ShowSlashIcon } from '../../common/icons';
import { formatDate } from '../../utils/formatDateTime';

const SMSTable = ({ smsData, isLoading, onView, onDelete }) => {
  const headers = ['Conversation ID', 'User', 'Phone', 'Template', 'Date', 'Messages count', 'Actions'];

  const getTitle = (title) => {
    if (title) {
      if (title?.length > 10) {
        return title.substring(0, 20) + '...'
      } else {
        return title;
      }
    }
    return '';
  }

  const smsTableBody = () => (
    <tbody>
      {smsData?.map(sms => (
        <tr key={sms?.conv_id} style={{ verticalAlign: 'middle' }}>
          <td style={{ width: '200px' }} title={sms?.conv_id}>{getTitle(sms?.conv_id)}</td>
          <td>{sms?.user_init_name || ''}</td>
          <td>{sms?.userPhone || ''}</td>
          <td title={sms?.template_title}>{getTitle(sms?.template_title)}</td>
          <td>{formatDate(sms?.first_message_date)}</td>
          <td>{sms?.count ||''}</td>
          <td>
            <div className="f-t-actions mw-50">
              {
                sms
                  ? <div className="cursor-p" onClick={() => onView(sms.conv_id)} title="View"><ShowIcon /></div>
                  : <div title="Cannot view"><ShowSlashIcon /></div>
              }
              <div className="cursor-p" onClick={() => onDelete(sms.conv_id)} title="Delete"><DeleteIcon /></div>
            </div>
          </td>
        </tr>
      ))}
    </tbody>
  );

  const smsTableLoader = () => (
    <tbody>
      <tr>
        <td colSpan="11" className="text-center">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </td>
      </tr>
    </tbody>
  );

  return (
    <TableWrapper>
      <TableHeader headers={headers} />
      {isLoading ? smsTableLoader() : smsTableBody()}
    </TableWrapper>
  );
}

export default SMSTable;