import React from 'react';
import TableWrapper from '../../common/table/tableWrapper.common';
import TableHeader from '../../common/table/tableHeader.common';
import { ShowIcon, DeleteIcon, ShowSlashIcon, DownloadFileIcon, NoFileIcon, SummaryIcon } from '../../common/icons';
import formatDateTime from '../../utils/formatDateTime';

const CallTable = ({ calls, isLoading, onView, onDelete, onDownload, onSummary }) => {
  const headers = ['ID', 'User', 'Type', 'Phone', 'Template', 'Status', 'Disposition', 'Sentiment', 'Requested time', 'Start time', 'End time', 'Duration', 'Actions'];

  const getTemplateTitle = (call) => {
    if (call?.template_title) {
      if (call?.template_title?.length > 10) {
        return call?.template_title.substring(0, 10) + '...'
      } else {
        return call?.template_title;
      }
    }
    return 'Conversational Pathway'.substring(0, 10) + '...';
  }

  const callTableBody = () => (
    <tbody>
      {calls?.map(call => (
        <tr key={call?.id} style={{ verticalAlign: 'middle' }}>
          <td>{call?.id}</td>
          <td>{call?.user_name || call?.callerPhone || ''}</td>
          <td style={{ width: '95px'}}>{call?.type+(call?.version ? ` ${call?.version}` : '')}</td>
          <td style={{ width: '285px'}}>{call?.phone || '-'}</td>
          <td title={call?.template_title || 'Conversational Pathway'}>{getTemplateTitle(call)}</td>
          <td>{call?.status}</td>
          <td>{call?.disposition || ''}</td>
          <td>{call?.sentiment}</td>
          <td>{formatDateTime(call?.requested_time)}</td>
          <td>{formatDateTime(call?.start_time)}</td>
          <td>{formatDateTime(call?.end_time)}</td>
          <td>{call?.duration || '---'}</td>
          <td>
            <div className="f-t-actions mw-96">
              {
                call?.status === 'in-progress' || call?.status === 'completed'
                  ? <div className="cursor-p" onClick={() => onView(call.id)} title="View"><ShowIcon /></div>
                  : <div title="Cannot view"><ShowSlashIcon /></div>
              }
              <div className={call?.summary ? 'cursor-p' : ''} onClick={() => onSummary(call)} title={call?.type === 'incomming-call' ? "Call data" : "Summary"}><SummaryIcon /></div>
              {
                call?.status === 'completed' && call?.record
                  ? <div className="cursor-p" onClick={() => onDownload(call)} title="Download" ><DownloadFileIcon /></div>
                  : <div title="No recording"><NoFileIcon /></div>
              }
              <div className="cursor-p" onClick={() => onDelete(call.id)} title="Delete"><DeleteIcon /></div>
            </div>
          </td>
        </tr>
      ))}
    </tbody>
  );

  const callTableLoader = () => (
    <tbody>
      <tr>
        <td colSpan="13" className="text-center">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </td>
      </tr>
    </tbody>
  );

  return (
    <TableWrapper>
      <TableHeader headers={headers} />
      {isLoading ? callTableLoader() : callTableBody()}
    </TableWrapper>
  );
}

export default CallTable;
