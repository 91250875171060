import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import currentUser from '../../../HOCs/currentUser.hoc';
import DetailsWrapper from '../../../common/wrappers/detailsWrapper.component';
import { Form, Button } from 'react-bootstrap';
import { SaveIcon, CopyIcon } from '../../../common/icons';
import { validateEmail } from '../../../utils/validateEmail';
import InputField from '../../../common/forms/input.form';
import VerificationStatusBar from '../verificationStatusBar';

import { useCreateCompanyMutation, useCheckTwilioMutation } from '../../../services/companyApi';
import { useCheckUserByEmailMutation, useAddUserByCompanyAdminMutation } from '../../../services/userApi';

const AddCompany = () => {
  const history = useHistory();

  const [createCompany, { isLoading: isCompanyLoading }] = useCreateCompanyMutation();
  const [checkTwilio, { isLoading: isTwilioChecking }] = useCheckTwilioMutation();
  const [checkUser, { isLoading: isUserChecking }] = useCheckUserByEmailMutation();
  const [createUser, { isLoading: isUserCreating }] = useAddUserByCompanyAdminMutation();

  const [formData, setFormData] = useState({
    name: '',
    twilio_acount_sid: '',
    twilio_auth_token: '',
    twilio_number: '',
    sip_acount_sid: '',
    sip_auth_token: '',
    sip_number: '',
    sms_acount_sid: '',
    sms_auth_token: '',
    sms_number: '',
    transfer_number: '',
    sip_transfer_number: '',

    first_name: '',
    last_name: '',
    email: '',
    password: '',
  });

  const [errors, setErrors] = useState({});

  const [twilioStatus, setTwilioStatus] = useState({ label: 'Verification of Twilio data', status: 'not_required' });
  const [sipStatus, setSipStatus] = useState({ label: 'Verification of SIP data', status: 'not_required' });
  const [smsStatus, setSmsStatus] = useState({ label: 'Verification of SMS data', status: 'not_required' });
  const [userStatus, setUserStatus] = useState({ label: 'Verification of User data', status: 'not_required' });
  const [companyStatus, setCompanyStatus] = useState({ label: 'Create company', status: 'not_required' });
  const [userCteateStatus, setUserCreateStatus] = useState({ label: 'Create admin user', status: 'not_required' });

  const [showVerificationBar, setShowVerificationBar] = useState(false);

  const addButtonRef = useRef(null);

  useEffect(() => {
    if (showVerificationBar && addButtonRef.current) {
      addButtonRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [showVerificationBar]);

  const handleChange = e => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const validateForm = (data) => {
    const errors = {};

    if (!data.name.trim()) {
      errors.name = 'Name is required';
    }

    const twilioFilled = [data.twilio_acount_sid, data.twilio_auth_token, data.twilio_number].filter(v => v.trim()).length;
    if (twilioFilled > 0 && twilioFilled < 3) {
      errors.twilio = 'If one Twilio field is filled, all three (SID, Token, Numbers) must be filled';
    }

    const sipFilled = [data.sip_acount_sid, data.sip_auth_token, data.sip_number].filter(v => v.trim()).length;
    if (sipFilled > 0 && sipFilled < 3) {
      errors.sip = 'If one SIP field is filled, all three (SID, Token, Numbers) must be filled';
    }

    const smsFilled = [data.sms_acount_sid, data.sms_auth_token, data.sms_number].filter(v => v.trim()).length;
    if (smsFilled > 0 && smsFilled < 3) {
      errors.sms = 'If one SMS field is filled, all three (SID, Token, Numbers) must be filled';
    }

    if (twilioFilled === 3 && !data.transfer_number.trim()) {
      errors.transfer_number = 'Transfer Number is required when Twilio fields are all filled';
    }

    if (sipFilled === 3 && !data.sip_transfer_number.trim()) {
      errors.sip_transfer_number = 'Sip Transfer Number is required when SIP fields are all filled';
    }

    if (!data.first_name.trim()) {
      errors.first_name = 'First Name is required';
    }
    if (!data.last_name.trim()) {
      errors.last_name = 'Last Name is required';
    }
    if (!data.email.trim()) {
      errors.email = 'Email is required';
    } else if (!validateEmail(data.email)) {
      errors.email = 'Invalid email format';
    }
    if (!data.password.trim()) {
      errors.password = 'Password is required';
    }

    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setShowVerificationBar(false);
    const validationErrors = validateForm(formData);
    if (Object.keys(validationErrors).length === 0) {
      setErrors({});
      const trimmedFormData = {};
      for (const key in formData) {
        trimmedFormData[key] = formData[key]?.trim ? formData[key].trim() : formData[key];
      }

      const twilioFilled = [trimmedFormData.twilio_acount_sid, trimmedFormData.twilio_auth_token, trimmedFormData.twilio_number].filter(v => v).length === 3;
      const sipFilled = [trimmedFormData.sip_acount_sid, trimmedFormData.sip_auth_token, trimmedFormData.sip_number].filter(v => v).length === 3;
      const smsFilled = [trimmedFormData.sms_acount_sid, trimmedFormData.sms_auth_token, trimmedFormData.sms_number].filter(v => v).length === 3;

      twilioFilled
        ? setTwilioStatus({ label: 'In progress: Verification of Twilio data', status: 'pending' })
        : setTwilioStatus({ label: 'Verification of Twilio data', status: 'not_required' });
      sipFilled
        ? setSipStatus({ label: 'Verification of SIP data', status: 'pending' })
        : setSipStatus({ label: 'Verification of SIP data', status: 'not_required' });
      smsFilled
        ? setSmsStatus({ label: 'Verification of SMS data', status: 'pending' })
        : setSmsStatus({ label: 'Verification of SMS data', status: 'not_required' });

      setUserStatus({ label: 'Verification of User data', status: 'pending' });
      setCompanyStatus({ label: 'Creating company', status: 'pending' });
      setUserCreateStatus({ label: 'Creating admin user', status: 'pending' });

      setShowVerificationBar(true);

      if (twilioFilled) {
        setTwilioStatus({ label: 'In progress: Verification of Twilio data', status: 'in_progress' });
        const twilioCheckResult = await checkTwilio({
          accountSid: trimmedFormData.twilio_acount_sid,
          authToken: trimmedFormData.twilio_auth_token,
          phoneNumberToCheck: trimmedFormData.twilio_number
        });
        if (twilioCheckResult.data?.length) {
          setTwilioStatus({ label: `Twilio error: ${twilioCheckResult.data?.[0]?.message}`, status: 'error' });
          return;
        } else {
          setTwilioStatus({ label: 'Success: Verification of Twilio data', status: 'success' });
        }
      }
      if (sipFilled) {
        setSipStatus({ label: 'In progress: Verification of SIP data', status: 'in_progress' });
        const twilioCheckResult = await checkTwilio({
          accountSid: trimmedFormData.sip_acount_sid,
          authToken: trimmedFormData.sip_auth_token,
          phoneNumberToCheck: trimmedFormData.sip_number
        });
        if (twilioCheckResult.data?.length) {
          setSipStatus({ label: `SIP error: ${twilioCheckResult.data?.[0]?.message}`, status: 'error' });
          return;
        } else {
          setSipStatus({ label: 'Success: Verification of SIP data', status: 'success' });
        }
      }
      if (smsFilled) {
        setSmsStatus({ label: 'In progress: Verification of SMS data', status: 'in_progress' });
        const twilioCheckResult = await checkTwilio({
          accountSid: trimmedFormData.sms_acount_sid,
          authToken: trimmedFormData.sms_auth_token,
          phoneNumberToCheck: trimmedFormData.sms_number
        });
        if (twilioCheckResult.data?.length) {
          setSmsStatus({ label: `SMS error: ${twilioCheckResult.data?.[0]?.message}`, status: 'error' });
          return;
        } else {
          setSmsStatus({ label: 'Success: Verification of SMS data', status: 'success' });
        }
      }

      setUserStatus({ label: 'In progress: Verification of User data', status: 'in_progress' });
      const userCheckResult = await checkUser(trimmedFormData.email);
      if (userCheckResult?.error) {
        setUserStatus({ label: `User error: ${userCheckResult?.error?.data?.message}`, status: 'error' });
        return;
      } else {
        setUserStatus({ label: 'Success: Verification of User data', status: 'success' });
      }

      setCompanyStatus({ label: 'Creating company', status: 'in_progress' });
      const {
        first_name, last_name, email, password,
        ...companyData
      } = trimmedFormData;

      const createCompanyResult = await createCompany(companyData);
      if (createCompanyResult.error) {
        setCompanyStatus({ label: `Company error: ${createCompanyResult?.error?.data?.message}`, status: 'error' });
        return;
      }
      setCompanyStatus({ label: 'Success: Company created', status: 'success' });

      const newCompany = createCompanyResult.data;

      setUserCreateStatus({ label: 'Creating admin user', status: 'in_progress' });
      const createUserResult = await createUser({ first_name, last_name, email, password, company_id: newCompany.id, role_id: 2 });
      if (createUserResult.error) {
        setUserCreateStatus({ label: 'Error while creating user', status: 'error' });
        return;
      }
      setUserCreateStatus({ label: 'Success: User created', status: 'success' });

      setShowVerificationBar(false);
      history.push('/companies');
    } else {
      setErrors(validationErrors);
    }
  };

  const handleCopyTwillioData = () => {
    setFormData(prev => ({
      ...prev,
      sip_acount_sid: prev.twilio_acount_sid,
      sip_auth_token: prev.twilio_auth_token,
      sip_number: prev.twilio_number,
      sip_transfer_number: prev?.transfer_number,
    }));
  };

  const handleCopySMSTwillioData = () => {
    setFormData(prev => ({
      ...prev,
      sms_acount_sid: prev.twilio_acount_sid,
      sms_auth_token: prev.twilio_auth_token,
      sms_number: prev.twilio_number,
    }));
  };

  const handleCopySMSSIPData = () => {
    setFormData(prev => ({
      ...prev,
      sms_acount_sid: prev.sip_acount_sid,
      sms_auth_token: prev.sip_auth_token,
      sms_number: prev.sip_number,
    }));
  };

  const AddButtons = () => (
    <div className="p-0 mb-2" ref={addButtonRef}>
      <Button variant="primary ms-3 w150" type="submit" onClick={handleSubmit}>
        <SaveIcon /> {(isCompanyLoading || isTwilioChecking || isUserChecking || isUserCreating) ? 'Loading...' : 'Add Company'}
      </Button>
      <Button variant="secondary ms-3 w100" onClick={() => history.push('/companies')}>
        Cancel
      </Button>
    </div>
  );

  const isTwilioRequired = !!formData.twilio_auth_token || !!formData.twilio_number || !!formData.twilio_acount_sid;
  const isSipRequired = !!formData.sip_auth_token || !!formData.sip_number || !!formData.sip_acount_sid;
  const isSmsRequired = !!formData.sms_acount_sid || !!formData.sms_acount_sid || !!formData.sms_number;

  return (
    <DetailsWrapper title="Company" label="Add" blockTitle="Add">
      <Form className="mb-6" onSubmit={handleSubmit}>
        <InputField
          label="Company Name*"
          name="name"
          value={formData.name}
          onChange={handleChange}
          errorMessage={errors?.name}
          autoFocus
        />

        <hr></hr>
        <h6 className="mt-4">Twilio data</h6>
        <InputField
          label={`Twilio Account SID${isTwilioRequired ? "*" : ""}`}
          name="twilio_acount_sid"
          value={formData.twilio_acount_sid}
          onChange={handleChange}
          errorMessage={errors?.twilio}
        />
        <InputField
          label={`Twilio Auth Token${isTwilioRequired ? "*" : ""}`}
          name="twilio_auth_token"
          value={formData.twilio_auth_token}
          onChange={handleChange}
          errorMessage={errors?.twilio}
        />
        <InputField
          label={`Twilio Number${isTwilioRequired ? "*" : ""}`}
          name="twilio_number"
          value={formData.twilio_number}
          onChange={handleChange}
          errorMessage={errors?.twilio}
        />
        <InputField
          label={`Transfer Number${isTwilioRequired ? "*" : ""}`}
          name="transfer_number"
          value={formData.transfer_number}
          onChange={handleChange}
          errorMessage={errors?.transfer_number}
        />

        <hr></hr>
        <h6 className="mt-4">SIP data</h6>
        <Button variant="secondary ms-3 mb-1 w240" disabled={!formData.twilio_auth_token || !formData.twilio_number || !formData.twilio_acount_sid} onClick={handleCopyTwillioData}>
          <CopyIcon /> Copy from Twilio data
        </Button>
        <InputField
          label={`SIP Account SID${isSipRequired ? "*" : ""}`}
          name="sip_acount_sid"
          value={formData.sip_acount_sid}
          onChange={handleChange}
          errorMessage={errors?.sip}
        />
        <InputField
          label={`SIP Auth Token${isSipRequired ? "*" : ""}`}
          name="sip_auth_token"
          value={formData.sip_auth_token}
          onChange={handleChange}
          errorMessage={errors?.sip}
        />
        <InputField
          label={`SIP Number${isSipRequired ? "*" : ""}`}
          name="sip_number"
          value={formData.sip_number}
          onChange={handleChange}
          errorMessage={errors?.sip}
        />
        <InputField
          label={`SIP Transfer Number${isSipRequired ? "*" : ""}`}
          name="sip_transfer_number"
          value={formData.sip_transfer_number}
          onChange={handleChange}
          errorMessage={errors?.sip_transfer_number}
        />

        <hr></hr>
        <h6 className="mt-4">Twilio SMS data</h6>
        <Button variant="secondary ms-3 mb-1 w240" disabled={!formData.twilio_auth_token || !formData.twilio_number || !formData.twilio_acount_sid} onClick={handleCopySMSTwillioData}>
          <CopyIcon /> Copy from Twilio data
        </Button>
        <Button variant="secondary ms-3 mb-1 w240" disabled={!formData.sip_auth_token || !formData.sip_number || !formData.sip_acount_sid} onClick={handleCopySMSSIPData}>
          <CopyIcon /> Copy from SIP data
        </Button>
        <InputField
          label={`SMS Account SID${isSmsRequired ? "*" : ""}`}
          name="sms_acount_sid"
          value={formData.sms_acount_sid}
          onChange={handleChange}
          errorMessage={errors?.sms}
        />
        <InputField
          label={`SMS Auth Token${isSmsRequired ? "*" : ""}`}
          name="sms_auth_token"
          value={formData.sms_auth_token}
          onChange={handleChange}
          errorMessage={errors?.sms}
        />
        <InputField
          label={`SMS Number${isSmsRequired ? "*" : ""}`}
          name="sms_number"
          value={formData.sms_number}
          onChange={handleChange}
          errorMessage={errors?.sms}
        />

        <hr></hr>
        <h6 className="mt-4">User Admin Fields</h6>
        <InputField
          label="First Name*"
          name="first_name"
          value={formData.first_name}
          onChange={handleChange}
          errorMessage={errors?.first_name}
        />
        <InputField
          label="Last Name*"
          name="last_name"
          value={formData.last_name}
          onChange={handleChange}
          errorMessage={errors?.last_name}
        />
        <InputField
          label="Email*"
          name="email"
          value={formData.email}
          onChange={handleChange}
          errorMessage={errors?.email}
        />
        <InputField
          label="Password*"
          name="password"
          value={formData.password}
          onChange={handleChange}
          errorMessage={errors?.password}
        />
        {showVerificationBar && <hr></hr>}
        {showVerificationBar && (
          <VerificationStatusBar
            twilioStatus={twilioStatus}
            sipStatus={sipStatus}
            smsStatus={smsStatus}
            userStatus={userStatus}
            companyStatus={companyStatus}
            userCteateStatus={userCteateStatus}
          />
        )}
        <hr></hr>
      </Form>
      <AddButtons />
    </DetailsWrapper>
  );
};

export default currentUser(AddCompany);
