import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const CallSummaryModal = ({ show, call, onHide }) => {
  return (
    <Modal show={show} onHide={onHide} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Call Summary</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p><span className="text-muted">Call ID:</span> {call?.id || 1}</p>
        <p><span className="text-muted">Phone:</span> {call?.phone || '---'}</p>
        <div className="large-text-container mh-150">{call?.summary ? call?.summary : 'There is no summary for this call.'}</div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide} autoFocus>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CallSummaryModal;
