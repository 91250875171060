import React from 'react';
import currentUser from '../../HOCs/currentUser.hoc';
import TTSFilesComponent from './local_tts_training_file.component';
import './local_call.css';

const LocalTTSFilePage = () => {
  return (
    <div className="main-content mt-5">
      <div className="row text-muted"><h3>Local TTS Names Training</h3></div>
      <div className="row bg-white p-2 h90p">
        <TTSFilesComponent />
      </div>
      <div className="empty-bottom-block" />
    </div>
  );
};

export default currentUser(LocalTTSFilePage);
