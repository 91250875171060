import React from 'react';

function TableHeader({ headers, isActiontSticky }) {
  return (
    <thead>
      <tr>
        {headers.map(header => (
          <th key={header} className={isActiontSticky && header === 'Actions' ? 'table-sticky-col' : ''}>{header}</th>
        ))}
      </tr>
    </thead>
  );
}

export default TableHeader;