import React from 'react';
import { Form } from 'react-bootstrap';

const TextareaField = ({ label, name, value, onChange, minHeight, maxHeight, errorMessage, rows = 3 }) => {
  const minHeightValue = minHeight || '120px';
  const maxHeightValue = maxHeight || '175px';
  return (
    <Form.Group controlId={`form${name}`} className="mb-2">
      <Form.Label className="text-muted mb-0">{label}</Form.Label>
      <Form.Control
        as="textarea"
        style={{ minHeight: minHeightValue, maxHeight: maxHeightValue }}
        rows={rows}
        name={name}
        value={value}
        onChange={onChange}
      />
      {errorMessage && <span className="alert-text">{errorMessage}</span>}
    </Form.Group>
  )
}

export default TextareaField;
