import { authApi } from '../services/authApi';
import { fileApi } from '../services/fileApi';
import { userApi } from '../services/userApi';
import { roleApi } from '../services/roleApi';
import { templateApi } from '../services/templateApi';
import { vocalApi } from '../services/vocalApi';
import { callApi } from '../services/callApi';
import { smsApi } from '../services/smsApi';
import { companyApi } from '../services/companyApi';
import authSlice from './authReducer';
import filesSlice from './filesReducer';
import meSlice from './meReducer';
import rolesSlice from './rolesReducer';

const rootReducer = {
  [authApi.reducerPath]: authApi.reducer,
  [fileApi.reducerPath]: fileApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
  [roleApi.reducerPath]: roleApi.reducer,
  [templateApi.reducerPath]: templateApi.reducer,
  [vocalApi.reducerPath]: vocalApi.reducer,
  [callApi.reducerPath]: callApi.reducer,
  [smsApi.reducerPath]: smsApi.reducer,
  [companyApi.reducerPath]: companyApi.reducer,
  auth: authSlice.reducer,
  files: filesSlice.reducer,
  me: meSlice.reducer,
  roles: rolesSlice.reducer,
};

export default rootReducer;