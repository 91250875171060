import React from 'react';
import { EmptyCircleIcon, HourglassIcon, CheckIcon, UnchekIcon } from '../../common/icons';

// props:
// twilioStatus, sipStatus, smsStatus, userStatus
// Возможные значения: "not_required", "pending", "in_progress", "success", "error"

// Функция для выбора иконки по статусу
const getIconByStatus = (status) => {
  switch (status) {
    case 'pending':
      return <EmptyCircleIcon />;
    case 'in_progress':
      return <HourglassIcon />;
    case 'success':
      return <CheckIcon />;
    case 'error':
      return <UnchekIcon />;
    case 'not_required':
    default:
      return null;
  }
};

const VerificationStatusBar = ({
  twilioStatus,
  sipStatus,
  smsStatus,
  userStatus,
  companyStatus,
  userCteateStatus,
}) => {
  const items = [
    twilioStatus,
    sipStatus,
    smsStatus,
    userStatus,
    companyStatus,
    userCteateStatus,
  ];

  return (
    <div className="verification-status-bar mb-3">
      {items.map((item, index) => {
        if (item?.status === 'not_required') {
          return null;
        }
        return (
          <div key={index} className="d-flex align-items-center mb-2">
            <div className="me-2">{getIconByStatus(item?.status)}</div>
            <div>{item?.label}</div>
          </div>
        );
      })}
    </div>
  );
};

export default VerificationStatusBar;
