import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import Select from 'react-select';
import { CallIcon, InfoIcon } from '../../common/icons';
import { useGetBlandCallStatusByIDMutation, useGetConvPathwayQuery, useInitBlandCallMutation } from '../../services/callApi';
import { useGetTemplatesListQuery } from '../../services/templateApi';
import countriesOptions from './countries.json';
import languages from './languages.json';

const CallComponent = () => {

  const [code, setCode] = useState(JSON?.parse(localStorage.getItem('call-code')) || { label: "United States (1)", value: "1" });
  const [voice, setVoice] = useState({ id: 'f', title: 'Female' });
  const [phone, setPhone] = useState('');
  const [supportedLanguages] = useState(languages);
  const [defaultLanguage, setDefaultLanguage] = useState(JSON?.parse(localStorage.getItem('call-language')) || languages[0]);
  const [template, setTemplate] = useState({});
  const [convPathway, setConvPathway] = useState({ id: null, title: 'not selected' });
  const [call, setCall] = useState(false);
  const [callID, setCallID] = useState(false);
  const [convPathways, setConvPathways] = useState([]);

  const [initCall, { isError }] = useInitBlandCallMutation();
  const { data: templates } = useGetTemplatesListQuery(null, { refetchOnMountOrArgChange: true });
  const [getCallStatus] = useGetBlandCallStatusByIDMutation();
  const { data: convData } = useGetConvPathwayQuery(null, { refetchOnMountOrArgChange: true });

  useEffect(() => {
    if (templates?.length && Object.keys(template).length === 0) {
      setTemplate(templates[0]);
    }
  }, [templates, template]);

  useEffect(() => {
    if (convData) {
      setConvPathways([{ id: null, title: 'not selected' }, ...convData]);
    }
  }, [convData]);

  // check call status
  useEffect(() => {
    let attempts = 0;
    let intervalId;

    const checkCallStatus = async () => {
      try {
        const response = await getCallStatus({ call_id: callID });
        const status = response?.data?.status;
        if (status === 'requested') {
          attempts++;
          if (attempts <= 4) {
            return;
          } else {
            clearInterval(intervalId);
            setCallID(null);
            setCall('The call is still in queue, we will call you as soon as the bot is free.');
            return;
          }
        } else {
          clearInterval(intervalId);
          setCallID(null);
          switch (status) {
            case 'in-progress':
              setCall('The call has been started.');
              break;
            case 'failed':
            case 'call_error':
            case 'queue_error':
              setCall('There were problems when trying to call, please try later.');
              break;
            case 'completed':
              setCall('The call was successfully completed');
              break;
            case 'busy':
              setCall('We called this number, but it is currently busy. Please try again later');
              break;
            default:
              break;
          }
        }
      } catch (error) {
        console.error('Error getting call status:', error);
      }
    };

    if (callID) {
      intervalId = setInterval(checkCallStatus, 4000);
      return () => clearInterval(intervalId);
    }
  }, [callID]);

  const onChangeLanguage = (e) => {
    const { value } = e.target;
    const { id } = e.target.selectedOptions[0];

    setDefaultLanguage({ name: id, lang: value });
    localStorage.setItem('call-language', JSON.stringify({ name: id, lang: value }));
  }

  const onChangeTemplate = (e) => {
    const { value } = e.target;
    const { id } = e.target.selectedOptions[0];
    onChangeConvPathway({ target: { selectedOptions: [{ id: null }] } });
    setTemplate({ id: id, title: value });
  }

  const onChangeConvPathway = (e) => {
    const { id } = e.target.selectedOptions[0];
    const obj = convPathways?.find(item => item?.id === (id || null));
    setConvPathway(obj);
  }

  const onChangeDynamicFieldForConv = (fieldName, newValue) => {
    setConvPathway((prev) => ({
      ...prev,
      dynamic_data: {
        ...prev.dynamic_data,
        [fieldName]: newValue
      }
    }));
  };

  const onChangeVoice = (e) => {
    const { value } = e.target;
    const { id } = e.target.selectedOptions[0];

    setVoice({ id: id, title: value });
  }

  const onChangeCode = (selectedOption) => {
    setCall(false);
    setCode(selectedOption);
    localStorage.setItem('call-code', JSON.stringify(selectedOption));
  };

  const onChangePhone = (e) => {
    setCall(false);
    setPhone(e.target.value)
  }

  const handleCall = async () => {
    if (!phone?.length || !template?.id) return;

    try {
      const fullPhoneNumber = `+${code?.value}${phone}`
      const response = await initCall(
        {
          phone: fullPhoneNumber,
          template_id: template.id,
          language: defaultLanguage.lang,
          voice: voice?.id,
          pathway_id: convPathway?.id,
          dynamic_fields: convPathway?.dynamic_data,
        }
      );
      if (!response.error) {
        setCallID(response?.data?.callID?.id);
        setCall('The request has been sent. Please wait for a call on your phone.');
      } else {
        setCall('There were problems when trying to call, please try later.');
      }
    } catch (error) {
      console.error('Error sending phone to API:', error);
    }
  }

  return (
    <div className="speech-container">
      <div className="bot-select mb-3 mt-2"><span>Code: </span>
        <Select
          options={countriesOptions}
          onChange={onChangeCode}
          placeholder="Select country code"
          isSearchable
          classNamePrefix="custom-select"
          value={code}
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              width: '275px',
              marginLeft: '5px',
              border: 'var(--bs-border-width) solid var(--bs-border-color)',
              borderRadius: 'var(--bs-border-radius)'
            })
          }}
        />
      </div>
      <div className="bot-select mb-3"><span>{`Phone: (${code?.value}) `}</span>
        <input
          type="phone"
          className={`form-control ms-2 mt-1 ${code?.value?.length == 1 ? 'w243' : 'w225'}`}
          placeholder="Phone is required"
          value={phone}
          onChange={onChangePhone}
          autoFocus
        />
      </div>
      <div className="bot-select mb-3"><span>Language:</span>
        <select className="form-select w240 bot-select ms-2" onChange={onChangeLanguage} value={defaultLanguage?.lang}>
          {supportedLanguages?.map(lang => (
            <option key={lang.lang} id={lang.name} value={lang.lang}>
              {lang.name}
            </option>
          ))}
        </select>
      </div>
      <div className="bot-select mb-3">
        <span>Template:</span>
        <select className="form-select w243 bot-select ms-2" onChange={onChangeTemplate}>
          {templates?.map(temp => (
            <option key={temp.id} id={temp.id} value={temp.title}>
              {temp.title}
            </option>
          ))}
        </select>
      </div>
      <div className="mb-3">
        <span>Conversational Pathways:</span><br />
        <select className="form-select w243 bot-select ms-6" onChange={onChangeConvPathway} value={convPathway?.title}>
          {convPathways?.map(convP => (
            <option key={convP.id} id={convP.id} value={convP.title}>
              {convP.title}
            </option>
          ))}
        </select>
      </div>
      {convPathway?.dynamic_data && Object.keys(convPathway?.dynamic_data)?.length ? (
        <div className="mb-3">
          <span>Dynamic fields:</span>
          <br />
          <div className="w400">
            <hr />
            {Object.keys(convPathway?.dynamic_data)?.map((fieldKey) => (
              <div className="bot-select ms-2 mb-3" key={fieldKey}>
                <span>{fieldKey}: </span>
                <input
                  type="text"
                  className="form-control ms-2 mt-1"
                  value={convPathway.dynamic_data?.[fieldKey] || ''}
                  onChange={(e) => onChangeDynamicFieldForConv(fieldKey, e.target.value)}
                />
              </div>
            ))}
            <hr />
          </div>
        </div>
      ) : null}
      <div className="bot-select">
        <span>Voice:</span>
        <select className="form-select w271 bot-select ms-2" onChange={onChangeVoice}>
          {[{ id: 'f', title: 'Female' }, { id: 'm', title: 'Male' }]?.map(voice => (
            <option key={voice.id} id={voice.id} value={voice.title}>
              {voice.title}
            </option>
          ))}
        </select>
      </div>
      <div className=" mt-3">
        <Button
          variant="primary"
          className="call-button"
          onClick={handleCall}
          disabled={!phone?.length || callID}
        >
          <CallIcon /> {callID ? <div className="d-inline-flex">Requested <div className="call-dot-container">
            <div className="call-dot"></div>
            <div className="call-dot"></div>
            <div className="call-dot"></div>
          </div></div> : 'Request a call'}
        </Button>
      </div>
      <br />
      {call ? <div className="d-flex"><p><InfoIcon /> {call}</p></div> : null}
      {isError ? <><br /><p className="alert-text"><span>Error: </span>Something went wrong... Please try again later</p></> : null}
      <br />
      <br />
      <div className="p-0 mb-2">
        <p><InfoIcon /> First select the country code, then enter the number (without + and code).</p>
        <p><InfoIcon /> You can select Template or Conversational Pathways. If you select a Conversational Pathways, it will override the Template field.</p>
        <p><InfoIcon /> To get started, click on the blue call button. The bot will call you.</p>
        <p><InfoIcon /> To end a conversation with the bot click the end conversation button on your phone.</p>
      </div>
    </div>
  );
};

export default CallComponent;
