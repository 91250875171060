import React from 'react';
import currentUser from '../../HOCs/currentUser.hoc';
import BatchSMSComponent from './batcSMS.component';

const BatchSMSPage = () => {
  return (
    <div className="main-content mt-5">
      <div className="row text-muted"><h3>Send SMS</h3></div>
      <div className="row bg-white p-2 h90p">
        <BatchSMSComponent className="call-button-icon mb-1" />
      </div>
      <div className="empty-bottom-block" />
    </div>
  );
};

export default currentUser(BatchSMSPage);
