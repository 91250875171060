// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.conv-messages {
  overflow-y: auto;
  min-height: 300px;
  height: 650px;
  min-width: 350px;
}

.message {
  padding: 8px 16px;
  margin: 8px;
  max-width: 80%;
  word-wrap: break-word;
  clear: both; 
}

.user-message {
  background-color: #DCF8C6;
  border-radius: 8px;
  float: right;
  min-width: 250px;
}

.bot-message {
  background-color: #F4F4F4;
  border-radius: 8px;
  float: left;
  min-width: 250px;
}

.conv-back-button-container {
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

@media (max-height: 660px) {
  .conv-back-button-container {
    position: relative;
  }
}

.chat-messages-role {
  color: #198754;
  font-size: small;
  font-weight: bold;
}

.chat-messages-time {
  color: #198754;
  font-size: small;
  float: right;
}

.refresh-sms-block {
  float: right;
  font-size: initial;
  margin-top: -15px;
}
`, "",{"version":3,"sources":["webpack://./src/components/sms_admin/conversation.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,iBAAiB;EACjB,aAAa;EACb,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;EACjB,WAAW;EACX,cAAc;EACd,qBAAqB;EACrB,WAAW;AACb;;AAEA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,kBAAkB;EAClB,SAAS;EACT,OAAO;EACP,WAAW;AACb;;AAEA;EACE;IACE,kBAAkB;EACpB;AACF;;AAEA;EACE,cAAc;EACd,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,cAAc;EACd,gBAAgB;EAChB,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,kBAAkB;EAClB,iBAAiB;AACnB","sourcesContent":[".conv-messages {\r\n  overflow-y: auto;\r\n  min-height: 300px;\r\n  height: 650px;\r\n  min-width: 350px;\r\n}\r\n\r\n.message {\r\n  padding: 8px 16px;\r\n  margin: 8px;\r\n  max-width: 80%;\r\n  word-wrap: break-word;\r\n  clear: both; \r\n}\r\n\r\n.user-message {\r\n  background-color: #DCF8C6;\r\n  border-radius: 8px;\r\n  float: right;\r\n  min-width: 250px;\r\n}\r\n\r\n.bot-message {\r\n  background-color: #F4F4F4;\r\n  border-radius: 8px;\r\n  float: left;\r\n  min-width: 250px;\r\n}\r\n\r\n.conv-back-button-container {\r\n  display: flex;\r\n  align-items: center;\r\n  position: absolute;\r\n  bottom: 0;\r\n  left: 0;\r\n  width: 100%;\r\n}\r\n\r\n@media (max-height: 660px) {\r\n  .conv-back-button-container {\r\n    position: relative;\r\n  }\r\n}\r\n\r\n.chat-messages-role {\r\n  color: #198754;\r\n  font-size: small;\r\n  font-weight: bold;\r\n}\r\n\r\n.chat-messages-time {\r\n  color: #198754;\r\n  font-size: small;\r\n  float: right;\r\n}\r\n\r\n.refresh-sms-block {\r\n  float: right;\r\n  font-size: initial;\r\n  margin-top: -15px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
