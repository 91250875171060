import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Form, Button } from 'react-bootstrap';
import { useGetTemplateWithDataQuery, useEditTemplateMutation, useCheckTemplatePhoneMutation } from '../../../services/templateApi';
import ConfirmationModal from '../../../common/modals/confirmation.modal';
import currentUser from '../../../HOCs/currentUser.hoc';
import DetailsWrapper from '../../../common/wrappers/detailsWrapper.component';
import InputField from '../../../common/forms/input.form';
import TextareaField from '../../../common/forms/textarea.form';
import { SaveIcon } from '../../../common/icons';

const TemplateEdit = () => {
  const history = useHistory()
  const { templateID } = useParams();
  const { data: template, isLoading, isError } = useGetTemplateWithDataQuery(templateID, { refetchOnMountOrArgChange: true });
  const [editTemplate, { isLoading: isEditLoading, isError: isEditError, error }] = useEditTemplateMutation();
  const [checkPhone] = useCheckTemplatePhoneMutation();

  const [formData, setFormData] = useState({
    title: '',
    data: '',
  });
  const [errors, setErrors] = useState({});
  const [formChanged, setFormChanged] = useState(false);

  const [phone, setPhone] = useState('');
  const [checkTemplate, setCheckTemplate] = useState(null);
  const [isPhoneModalShow, setPhoneModalShow] = useState(false);

  const [dataPrompt, setDataPrompt] = useState('');

  useEffect(() => {
    if (template) {
      setFormData({
        title: template?.title,
        data: template?.fileData,
      });
      setPhone(template?.phone);
      setDataPrompt(template?.dataPrompt);
    }
  }, [template]);

  const handleChange = e => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value,
    }));
    setFormChanged(true);
  };

  const onChangePhone = (e) => {
    setPhone(e.target.value);
    setFormChanged(true);
  }

  const onChangePrompt = (e) => {
    setDataPrompt(e.target.value);
    setFormChanged(true);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm(formData);
    if (Object.keys(validationErrors).length === 0) {
      setErrors({});

      if (phone?.trim()?.length) {
        const templateWithPhone = await checkPhone({ phone });
        if (templateWithPhone?.data?.id && templateWithPhone?.data?.id !== Number(templateID)) {
          setPhoneModalShow(true);
          setCheckTemplate(templateWithPhone?.data);
          return;
        }
      }

      const trimmedFormData = {};
      for (const key in formData) {
        trimmedFormData[key] =
          formData[key]?.trim ? formData[key].trim() : formData[key];
      }
      trimmedFormData.phone = phone;
      if (dataPrompt?.length) trimmedFormData.dataPrompt = dataPrompt;
      const result = await editTemplate({ templateID, body: trimmedFormData });
      if (!result.error) history.push('/templates');
    } else {
      setErrors(validationErrors);
      setFormChanged(false);
    }
  };

  const onHideModal = () => {
    setPhoneModalShow(false);
    setCheckTemplate(null);
  }

  const onConfirmModal = async () => {
    setErrors({});
    await editTemplate({ templateID: checkTemplate?.id, body: { title: checkTemplate?.title, phone: null } });

    const trimmedFormData = {};
    for (const key in formData) {
      trimmedFormData[key] =
        formData[key]?.trim ? formData[key].trim() : formData[key];
    }
    trimmedFormData.phone = phone;
    if (dataPrompt?.length) trimmedFormData.dataPrompt = dataPrompt;
    const result = await editTemplate({ templateID, body: trimmedFormData });
    if (!result.error) history.push('/templates');

    if (!result.error) {
      onHideModal();
      history.push('/templates');
    }
  }

  const validateForm = (formData) => {
    const errors = {};
    if (!formData?.title?.trim()) {
      errors.title = 'Title is required';
    }
    if (!formData?.data?.trim()) {
      errors.data = 'Text is required';
    }
    return errors;
  };

  const EditButtons = () => (
    <div className="p-0 mb-2 mt-3">
      <Button
        variant={`${!formChanged ? 'secondary' : 'primary'} ms-3 w150`}
        type="submit"
        onClick={handleSubmit}
        disabled={!formChanged}
      >
        <SaveIcon /> {isEditLoading ? 'Loading...' : 'Save Changes'}
      </Button>
      <Button variant="secondary ms-3 w100" onClick={() => history.push('/templates')}>
        Cancel
      </Button>
      {isEditError && 
      <div className="aler-text ms-2" role="alert">Error: {error?.data?.message}
      </div>}
    </div>
  )

  if (isLoading) {
    return (
      <DetailsWrapper title="Template" label="Edit" blockTitle="Edit">
        <div>Loading...</div>;
        <EditButtons />
      </DetailsWrapper>
    )
  }

  if (isError) {
    return (
      <DetailsWrapper title="Template" label="Edit" blockTitle="Edit">
        <div>Error fetching template data</div>
        <EditButtons />
      </DetailsWrapper>
    )
  }

  return (
    <DetailsWrapper title="Template" label="Edit" blockTitle="Edit">
      <Form className="mb-6" onSubmit={handleSubmit}>
      <InputField
          label="Title*"
          name="title"
          value={formData.title}
          onChange={handleChange}
          errorMessage={errors?.title}
          autoFocus
        />
        <div className="text-muted mt-2 mb-3">
          <span>{`Phone (if needed, for incoming calls)`}</span>
          <input
            type="phone"
            className={`form-control mt-1 w243`}
            value={phone}
            onChange={onChangePhone}
          />
        </div>
        <TextareaField
          label="Text*"
          name="data"
          value={formData.data}
          onChange={handleChange}
          errorMessage={errors?.data}
          minHeight="400px"
          maxHeight="500px"
        />
        <TextareaField
          label="Prompt for additional data (for incoming calls)"
          name="dataPrompt"
          value={dataPrompt}
          onChange={onChangePrompt}
          minHeight="150px"
          maxHeight="250px"
        />
      </Form>
      <EditButtons />
      <ConfirmationModal
        show={isPhoneModalShow}
        onHide={onHideModal}
        onConfirm={onConfirmModal}
        title={`Phone ${phone} is already in use`}
        description={`The specified phone is used for the "${checkTemplate?.title}" template. Do you want to assign phone ${phone} to this template ?`}
        className='modal w320'
      />
    </DetailsWrapper>
  );
};

export default currentUser(TemplateEdit);